
import firebase from 'firebase/app'
import 'firebase/firestore'
import config from './config'

// Get a Firestore instance
const firebaseApp = firebase
  .initializeApp(config.firebase)
const db = firebaseApp.firestore()
// firebaseApp.analytics() //壊れる
export default db
