const config = {
  env: "prod",
  host: 'https://chocomonaca.com/',
  api: 'https://api.chocomonaca.com',
  twitterLoginUrl: 'https://api.chocomonaca.com/auth/twitter/login',
  firebase: {
    apiKey: "AIzaSyAVDRrPz1r9SFVFSBdwWSdOxsDH6FrCIsA",
    authDomain: "chocomonaca-prod.firebaseapp.com",
    projectId: "chocomonaca-prod",
    storageBucket: "chocomonaca-prod.appspot.com",
    messagingSenderId: "212610030815",
    appId: "1:212610030815:web:4c2f0546a76b0e3c565d6a",
    measurementId: "G-7PCDDBQEPK"
  }
}

module.exports = config;