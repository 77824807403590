import session from '../utilities/session'
import axios from '../utilities/axios'
import config from '../config'

import Swal from 'sweetalert2'
/* eslint-disable no-param-reassign */

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // tickerの存在確認
    isValidTicker(context, ticker) {
      return axios
        .get(`${config.api}/nft/isValidTicker?ticker=${ticker}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // NFTの出品一覧
    getOpenSellOrders(context, ticker) {
      return axios
        .get(`${config.api}/nft/getSellOrders?ticker=${ticker}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // モナコインのレートを取得
    getMonaRate(context) {
      return axios
        .get(`${config.api}/nft/monaRate`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          // Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // ユーザーIDを指定して、そのユーザが公開している場合、NFT一覧を取得
    getPublicNftCollection(context, id) {
      return axios
        .get(`${config.api}/nft/publicCollection?id=${id}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // チャートデータを取得
    getChart(context, obj) {
      return axios
        .get(`${config.api}/nft/chart?ticker=${obj.ticker}&period=${obj.period}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 約定履歴を取得
    getTradeHistory(context, obj) {
      return axios
        .get(`${config.api}/nft/tradeHistory?ticker=${obj.ticker}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          // Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 公開しているホルダー一覧を取得
    getHolders(context, obj) {
      return axios
        .get(`${config.api}/nft/holders.json?ticker=${obj.ticker}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          // Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    }

  },
}
