import Vue from 'vue'

import 'bootswatch/dist/lux/bootstrap.min.css'
import './assets/css/animations.styl'
import './assets/css/index.styl'
import './utilities'
// import "./firestore"

import VeeValidate from 'vee-validate'
import App from './App.vue'
import store from './store'
import router from './router'
import { firestorePlugin } from 'vuefire'
import VueTippy, { TippyComponent } from "vue-tippy"
import Toasted from 'vue-toasted'
import VueLazyload from 'vue-lazyload'

Vue.use(VeeValidate)
Vue.use(firestorePlugin)
Vue.use(VueTippy)
Vue.use(Toasted)

let globalData = new Vue({
  data: { $notificationCount: 0 }
});
Vue.mixin({
  computed: {
    $notificationCount: {
      get: function () { return globalData.$data.$notificationCount },
      set: function (newParam) { globalData.$data.$notificationCount = newParam; }
    }
  }
})

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "/static/img/loading.png",
  loading: "/static/img/loading.png",
  attempt: 1
})

// eslint-disable-next-line
new Vue({
  el: '#app',
  store,
  router,
  components: {
    App,
  },
  template: '<App/>',
})
