<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">利用規約</h1>
      
      <p class="text-base font-medium" style="color:630;">
        この利用規約（以下、「本規約」といいます。）は、当社が提供する「CHOCOMONACA」サービス（以下、「本サービス」といいます。）の利用条件を定めるものです。お客様は、本規約に同意の上、本サービスをご利用いただくものとします。ユーザーは、本サービスを利用する前に、本規約をよくお読みください。本規約に同意されないユーザーは、本サービスを利用することはできません。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        1.（適用） 
      </h2>
      <p class="text-base font-medium" style="color:630;">
        1. 本規約は、本サービスの利用に関する当社とユーザー（第３条に定義します。以下同じ。）との間の権利義務関係を定めることを目的とし、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社が当社ウェブサイト（第３条に定義します。以下同じ。）上で随時掲載する本サービスに関する説明書、ガイドライン、ポリシー、注意事項その他の個別規程等は、本規約の一部を構成するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. ユーザーは、本規約の内容に同意した上で、本サービスを利用するものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        2.（本サービス）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 本サービスは、ブロックチェーン上のイラスト等のNon-Fungible Token（以下、「NFT」といいます。）を当社が指定する暗号資産（以下、「本暗号資産」といいます。）と交換できるユーザー間のプラットフォームです。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザー間のNFTの交換、出品、購入等（以下、「交換等」といいます。）に関しては、すべてユーザーの自己責任にて行っていただきます。当社は、出品者の出品が正当な権利に基づくものであること等について保証しません。また、当社は、自らNFTの交換等を行うものではなく、交換等の委託を受けるものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 当社が取り扱う NFT は、資金決済に関する法律第２条第５項に定義される「暗号資産」には該当しません。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        3.（定義） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 本規約において使用する以下の用語は、それぞれ以下に定める意味を有するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 「知的財産権」とは、あらゆる国、州、地域又は法域の下での、すべての特許権、実用新案権、商標権、意匠権、著作権（著作権法第27条及び第28条の権利を含みます。）その他の知的財産権（それらの権利を申請し、及びそれらの権利につき登録、更新又は延長等の手続を行う権利を含みます。）をいいます。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 「当社ウェブサイト」とは、当社が運営する本サービス専用のウェブサイトを意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 「登録希望者」とは、第４条において定義された「登録希望者」を意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 「登録情報」とは、第４条において定義された「登録情報」を意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 「ユーザー」とは、第４条に基づき本サービスの利用者としての登録がなされた個人及び法人を意味します。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (6) 「暗号資産」とは、資金決済に関する法律（平成21年法律第59号）第2条第5項に定める暗号資産を意味します。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (7) 「商品」とは、本サービスにおいて取引の目的となるイラスト等のNFT を意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (8) 「出品」とは、ユーザーが、本サービスにて、商品の取引に必要な情報を掲載・発信し、他のユーザーが閲覧可能かつユーザーが商品を取引できる状態にすることを意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (9) 「出品者」とは、本サービスを通じて商品を出品するユーザーを意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (10) 「購入者」とは、本サービスを通じて商品を購入するユーザーを意味します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (11) 「出品価格」とは、出品者が本サービスにおいて商品を出品する際に設定する商品の価格を意味します。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (12) 「出品手数料」とは、出品者が商品を出品する時に出品者から当社に対して支払われる手数料（消費税を含みます。）を意味します。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (13) 「利用契約」とは、第４条第４項に基づき当社とユーザーの間で成立する、本規約の諸規定に従った本サービスの利用契約を意味します。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        4. （新規登録） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 本サービスの利用を希望する者（以下、「登録希望者」といいます。）は、本規約に同意した上で、当社所定の情報（以下、「登録情報」といいます。）を当社所定の方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社は、当社の基準及び手続に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知し、この通知により登録希望者のユーザーとしての登録は完了したものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 登録希望者の登録情報の内容により、追加で登録情報の提供が必要になる場合があります。
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. 第２項に定める登録の完了時に、本規約の諸規定に従った本サービスの利用契約がユーザーと当社の間に成立するものとし、ユーザーは本サービスを当社所定の方法で利用することができるようになります。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        5. 当社は、登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 刑事裁判手続において有罪判決を受けた若しくは受ける可能性が認められる場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 第15条に定める者と当社が判断した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 過去に当社との契約その他の合意に違反した場合又は本サービスその他当社のサービスの登録を拒否若しくは取消しされた場合、又はその関係者に該当する場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (6) マネー・ローンダリング、テロ資金及び大量破壊兵器の拡散に対する資金供与（以下、これらの行為を総称して「マネー・ローンダリング等」といいます。）の危険性が高いと判断した場合 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (7) 本規約に違反する行為を行い又は行うおそれがある場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (8) その他、当社が登録を適当でないと判断した場合
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        5. （登録情報等の変更・追加・定期確認） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        ユーザーは、登録情報等の変更、追加等があった場合には、遅滞なく当社所定の方法により当社に届け出るものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        6. （登録メールアドレス及びパスワードの管理） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. ユーザーは、自己の責任において、本サービスで用いるID及びパスワードを適切に管理及び保管しなければならず、これらを第三者に利用させ又は貸与、譲渡、名義変更、売買等をしてはなりません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザーは、本サービスに関するID及びパスワードが盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        7. （手数料） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 出品者は、当社に対し、当社所定の出品手数料（消費税を含みます。）を、第9条で定める商品の出品時に、本暗号資産で支払うものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザーは、当社に対し、当社が別に定める送付手数料（消費税を含みます。）を、商品を当社が移転する際に本暗号資産で支払うものとします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        8. （本サービスの利用）  
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. ユーザーは、有効にユーザーとして登録されている期間内に限り、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社所定の方法に従い、本サービスを利用することができます。
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザーは、本サービスの利用に際して、自己の費用と責任において、ユーザーが自ら管理する本暗号資産のウォレット（当社が別途指定する条件を充足するものに限り、以下、「本ウォレット」といいます。）を準備するものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 本ウォレットのほか、本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備（必要なアプリケーションのインストールを含みます。）及び維持は、ユーザーの費用と責任において行うものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        5. ユーザーは、本規約に違反することにより又は本サービスの利用に関連して当社に損害を与えた場合、その損害を賠償しなければなりません。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        9. （出品） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 出品者は、本サービスを利用して取引を行うことを目的として、当社所定の方法により、商品の出品を行うことができるものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 出品者は、出品時に、購入者から交換代金たる本暗号資産の送金を直接受けるための本ウォレットのアドレス（以下、「送金用アドレス」といいます。）を本サービス上で登録するものとします。なお、送金用アドレスが自己の管理するアドレスでなく、暗号資産取引所等が管理するウォレットのアドレスである場合等当社所定の条件を満たさないときは、当該出品者が交換代金を受領できなかったことにより出品者に生じた損害について、当社は責任を負わないものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 出品者は、出品時に登録した送金用アドレスに、自ら出品価格と同額の本暗号資産の送金等を行った場合又は購入者以外の第三者から同額の本暗号資産の送金等が行われた場合には、購入者から交換代金の支払を受けられない場合があることを予め承諾するものとし、当該送金等の誤りにより出品者に生じた損害については、当社は責任を負わないものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. 出品者は、第10条第2項で定める交換契約の成立前まで出品を取り消すことができます。なお、出品者は、交換契約成立後は当該契約をキャンセルすることはできません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        5. 出品者が本規約に違反した出品を行った場合、又は出品者が真に交換契約を締結する意思のない出品を行ったと当社が判断した場合その他当社が不適当と判断した場合、当社は、出品者に事前に通知することなく、出品を無効とすることができます。出品が無効となった場合、当社は当該出品に対する購入を無効とすることができます。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        6. 当社は、交換契約の成立をユーザーに対し保証するものではありません。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        10. （購入）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 購入は、出品者と当社が指定する暗号資産と交換することで商品を購入することができるものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 本サービス上に出品されている商品について購入者により購入ボタンが押された時点で、当該商品と当社が指定する暗号資産についての出品者と購入者の間の交換契約が成立します。購入者は、交換契約成立後は交換契約のキャンセル及び商品の購入数量等の変更はできないものとし、いかなる理由があっても成立した取引の無効又は取り消しを主張しないものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 購入者は、登録された出品者の送金用アドレスに、指定された数量の本暗号資産を送金するものとします。なお、当該送金時に、購入者が異なる数量の本暗号資産を送金した場合は、購入者は商品を取得できず、なおかつ本暗号資産の返金を受けることはできないことを予め承諾し、当社に対して、何ら請求等を行わないものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. 購入者により購入ボタンが押された後、購入者が、60分以内に前項本文の送金をしなかった場合、当社は、購入者による本サービスの利用を最低でも7日間停止等するものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        11. （NFTの移転） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. システム上の負荷その他の状況により、当社による出品者から購入者への商品の移転が遅延する場合があります。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社が、法令諸規則等に従い、ユーザーの申請内容や購入者の属性から商品の移転が不適当と認めた場合は、商品の移転を一時的に停止し、さらに商品の移転ができなくなる場合があります。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 当社所定の基準を上回る場合又は合理的な理由に基づき当社が別途通知した場合を除き、商品の移転に係る手続は、購入者による交換代金の支払を当社が確認した後、原則として速やかに行うものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        12. （禁止事項）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. ユーザーは、本サービスの利用に当たり、以下の各号のいずれかに該当する行為をしてはなりません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 当社、又は本サービスの他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（これらの侵害を直接又は間接に惹起する行為を含みます。） 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) マネー・ローンダリング等に関連する行為若しくはこれに類似する行為、犯罪行為に関連する行為又は公序良俗に反する行為 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 本サービスに関し利用しうる情報を改ざんする行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (6) 広告配信等の他のユーザーに対する勧誘行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (7) NFTの二重譲渡に該当する行為又はこれを試みる行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (8) 真に交換契約を締結する意思なく出品又は購入する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (9) その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (10) 当社による本サービスの運営を妨害するおそれのある行為 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (11) 同一人物が本サービスに複数の登録をし、又は登録をしようとする行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (12) 架空の名義又は他人の名義など本人名義以外の名義で本サービスに登録し、又は登録しようとする行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (13) ユーザー以外のNFTを出品、交換する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (14) NFT の価格の変動を図る目的のために行う次に掲げる行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        ・ 行為者が直接経験又は認識していない合理的な根拠のない事実を不特定多数の者に流布すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        ・ 他人を錯誤に陥れるような手段を用いて詐欺的な行為を行うこと。徒に他人の射幸心をあおるような言動を行うこと
      </p>

      <p class="text-base font-medium" style="color:630;">
        ・ 暴行又は脅迫を用いること
      </p>

      <p class="text-base font-medium" style="color:630;">
        (15) NFTの交換等に関して不正の手段、計画若しくは技巧をし、又は重要な事項について虚偽若しくは誤解を生じさせる表示をする行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (16) 架空の名義又は他人の名義など本人名義以外の名義で取引し、又は取引しようとする行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (17) 当社に対し、虚偽又は故意に誤った情報を申告する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (18) NFT を賭博・ギャンブルに利用する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (19) 法令に違反し、又は違反するおそれのある行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (20) その他、当社が不適切と判断する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社は、本サービスにおけるユーザーが前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、当社の裁量で、ユーザーに事前に通知することなく、当該ユーザーが送信した情報の全部又は一部の削除又は当該ユーザーによる本サービスの利用停止等の措置をとることができるものとします。当社は、本項に基づき当社が行った措置に起因してユーザーに生じた損害について責任を負いません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 前項の定めによりユーザーの本サービスの利用が停止等された場合でも、当社は、当該停止等の時までにユーザーから受領した書類等を返還する義務を負わないものとします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        13. （本サービスの停止等）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) コンピューター、通信回線等が事故により停止した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 火災、停電、天災地変等の不可抗力により本サービスの運営ができなくなった場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) ハッキングその他の方法により当社の資産が盗難された場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 本サービス提供に必要なシステムの異常の場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (6) 本サービスの不正利用等の調査を行う場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (7) NFT の取扱がなくなった場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (8) その他、当社が停止又は中断を必要と判断した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社は、当社の都合により、本サービスの全部又は一部の提供を終了することができます。この場合、当社はユーザーに事前に通知するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 当社は、本条に基づき当社が行った措置によりユーザーに生じた損害について責任を負いません。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        14. （権利帰属）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 当社ウェブサイト及び本サービスに関する所有権及び知的財産権（商品の著作権を含みます。）は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これらに限定されません。）をしないものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社ウェブサイト又は本サービスにおいて、ユーザーが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、無償で自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        15. （反社会的勢力の排除） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. ユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 暴力団員等が経営を支配していると認められる関係を有すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 暴力団員等が経営に実質的に関与していると認められる関係を有すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 自己、自社もしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザーは、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 暴力的な要求行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 法的な責任を超えた不当な要求行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) その他前各号に準ずる行為 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. ユーザーが、暴力団員等もしくは第１項各号のいずれかに該当し、前項各号のいずれかに該当する行為をし、又は第１項の規定にもとづく表明・確約に関して虚偽の申告をしたことが判明し、ユーザーとの取引を継続することが不適切である場合には、ユーザーは当社から請求があり次第、当社に対する一切の債務の期限の利益を失い、直ちに債務を弁済するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. 前項の規定の適用により、ユーザーに損害が生じた場合にも、当社になんらの請求をしないものとします。また、当社に損害が生じたときは、ユーザーがその責任を負うものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        16. （登録取消等）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて本サービスの利用を一時的に停止し、ユーザーとしての登録を取消し、又は本サービスの利用契約を解約することができます。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 本規約のいずれかの条項に違反した場合 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 登録情報に虚偽の事実があることが判明した若しくは虚偽である可能性があると当社が判断した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (3) 当社、他のユーザーその他の第三者に損害を生じさせるおそれのある目的若しくは方法で本サービスを利用した、又は利用しようとした場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (4) 手段を問わず、本サービスの運営を妨害した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (5) 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始等の申立てを受け、若しくは自ら申し立てた場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (6) 営業の廃止、変更、譲渡、又は解散の決議をしたとき 
      </p>

      <p class="text-base font-medium" style="color:630;">
        (7) 自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
      </p>

      <p class="text-base font-medium" style="color:630;">
        (8) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (9) 租税公課の滞納処分を受けた場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (10) 死亡した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (11) 当社からの連絡に対して応答がない場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (12) 第15条第1項各号又は同条第2項各号に該当する場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (13) ユーザーが当社若しくは当社従業員に対して、社会通念上不適切な言動を行った場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (14) 当社が本人確認に応じるように求めたにもかかわらず、これに応じない場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (15) 当社がユーザーについてマネー・ローンダリング等の危険性が高いと判断した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        (16) その他、当社がユーザーとしての登録の継続を適当でないと判断した場合
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 第１項各号のいずれかの事由に該当した場合で、登録取消の場合、当社はユーザーに事前に通知することなく、当社所定の時点で、ユーザーが本サービス上で保有するNFT を交換できるものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について責任を負いません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        5. ユーザーは、当社所定の方法で当社に通知することにより、自己のユーザーとしての登録を取消し、本サービスの利用契約を解約することができます。
      </p>

      <p class="text-base font-medium" style="color:630;">
        6. 本条の定めによりユーザーについて本サービスの利用を一時的に停止し又はユーザーとしての登録を取消し、本サービスの利用契約を解約した場合（ただし、前項の場合を除きます。）でも、当社は、当該ユーザーにその理由を明らかにする義務を負わないものとします。またこの場合、当社は、当該停止又は取消の時までにユーザーから受領した書類等を返還する義務を負わないものとします。
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        17. （免 責）
      </h2>
 
      <p class="text-base font-medium" style="color:630;">
        1. 当社は、ユーザーに対し本規約において規定されていない事項についていかなる保証も行うものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社は、NFT の交換の場を提供するサービスを行うものであって、成立した交換契約において無効、取消、解除その他契約の成立又は有効性を妨げる事由がないことについて何ら保証するものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. ユーザーは、本サービス又は当社ウェブサイトに関連してユーザーと他のユーザー又は第三者との間において生じた取引、連絡、紛争等について自己の責任において処理及び解決するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        5. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更がないことを何ら保証するものではありません。また、ユーザーは、ユーザーによるユーザーのメッセージ若しくは情報の削除若しくは消失､ユーザーによるユーザーの登録の取消又はユーザーによるデータの消失若しくは機器の故障、損傷等について、自己の責任において処理及び解決するものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        6. 当社は、当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合、当社ウェブサイト以外のウェブサイト及びそこから得られる情報についていかなる保証も行うものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        7. 当社は、本サービスに事実上又は法律上の瑕疵（セキュリティ等に関する欠陥、エラーやバグ、権利侵害等を含みます。）がないこと、並びに安全性、信頼性、正確性、完全性、有効性及び特定の目的への適合性を明示的にも黙示的にも保証しておりません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        8. 当社は、NFT 及び暗号資産に対する法律、政令、法令、規則、命令、通達、条例、ガイドラインその他の規制（以下、「法令等」といいます。）若しくは関連した所得税及び消費税を含む税制の将来の制定又は変更がないことを何ら保証するものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        9. 当社は、交換が成立した場合であっても、システム異常又はその他の原因で誤って交換が成立したとき又は実勢価格と明らかに乖離した価格で交換が成立したときは、当社が当該交換を取消すことができるものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        10. 当社は、NFT 及び暗号資産に対する法令等又は関連した所得税及び消費税を含む税制の将来の制定又は変更の効力が過去に遡及した場合に、これによりユーザーに損害が発生しないことを何ら保証するものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        11. 購入者と出品者の国内外の税務上の取り扱いについては、購入者と出品者の責任で確認・履行すべきものであり、当社は何ら保証するものではありません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        12. 当社は、NFT 自体の価値、安定性及び適法性につき、いかなる保証を行うものではありません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        13. 当社は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        14. 前各項の規定にかかわらず、当社が提供するサービスに起因して、当社の責めに帰すべき事由により、ユーザー（事業として又は事業のために契約の当事者となる場合以外の個人に限ります。）に対して、その損害を賠償する責任を負う場合、当社に故意又は重大な過失がある場合を除き、当社が負う損害賠償の範囲は、当社の行為を直接の原因として現実に発生した損害に限定され、かつ、損害の事由が発生した時点から遡って 1 ヶ月の間にユーザーから現実に受領した第7条に定める手数料の総額を上限とします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        15. 第1項から第13項までの規定にかかわらず、当社が提供するサービスに起因してユーザー（事業として又は事業のために契約の当事者となる場合に限り、消費者契約法の適用のある場合を除きます。）に損害が発生した場合でも、当社は、当社に故意又は重大な過失がある場合を除き、当該ユーザーに対して、責任を負わないものとします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        18. （秘密保持） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 本規約において「秘密情報」とは、本規約又は本サービスに関連して、ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 第２項の定めに拘わらず、ユーザーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
      </p>

      <p class="text-base font-medium" style="color:630;">
        4. ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        19. （本規約等の変更）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. 当社は以下の場合に、当社が必要と認めた場合は、本規約を変更することができます。
      </p>

      <p class="text-base font-medium" style="color:630;">
        (1) 本規約の変更が、ユーザーの一般の利益に適合するとき
      </p>

      <p class="text-base font-medium" style="color:630;">
        (2) 本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 前項の場合、当社は、変更後の本規約の効力発生日の１週間前までに、本規約を変更する旨及び変更後の本規約の内容とその効力発生日を当社サイト本アプリ上に掲示する等適切な方法で通知します。
      </p>

      <p class="text-base font-medium" style="color:630;">
        3. 前二項に定めるほか、当社は、当社が定めた方法でユーザーの同意を得ることにより、本規約を変更することができます。
      </p>

       <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        20. （通知等） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡又は通知は、当社所定の方法で行うものとします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        21. （本規約の譲渡等）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        1. ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2. 当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項においてあらかじめ同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        22. （準拠法及び管轄裁判所）
      </h2>

      <p class="text-base font-medium" style="color:630;">
        本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。 
      </p>

      <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        23. （協 議） 
      </h2>

      <p class="text-base font-medium" style="color:630;">
        当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。 
      </p>

      <p class="text-base font-medium" style="color:630;">
        2021年4月6日制定
      </p>

    </div>
  </div>
</template>


<style>
  #content a{ color:#631; text-decoration:underline; }
</style>


<script>
export default {}
</script>