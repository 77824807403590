import session from '../utilities/session'
import axios from '../utilities/axios'
import config from '../config'

import Swal from 'sweetalert2'
/* eslint-disable no-param-reassign */

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // ICLのアドレスを取得
    getIclAddress(context) {
      return axios
        .get(`${config.api}/api/event/iclAddress`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 送金チェック
    sendCheck(context) {
      return axios
        .get(`${config.api}/api/event/sendCheck`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },

  },
}
