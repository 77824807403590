<template>
  <div>
    <header-template />
    <div id="content" class="container my-4 lg:my-16">
      <router-view/>
    </div>
    <footer-template/>
  </div>
</template>




<script>
import header from './views/templates/header.vue'
import footer from './views/templates/footer.vue'
import session from './utilities/session'

export default {
  name: 'App',
  components: {
    'header-template': header,
    'footer-template': footer
  },
  data() {
    return {
      transitionName: 'slide-up'
    }
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        //swal('ログインに成功しました', 'Ⓜ️', 'success')
        if (session.getLastPass()) {
          this.$router.push({path: session.getLastPass()})
        } else {
          this.$router.push('/collection')
        }
      } else {
        // swal('You have been logged out.', 'Good bye!', 'info')
        this.$router.push('/collection')
      }
    },
    $route(to, from) {
      this.setTransition(to, from)
    }
  },
  created() {},
  methods: {
    setTransition(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      if (toDepth === fromDepth) {
        this.transitionName = 'slide-up'
      } else {
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    }
  }
}
</script>