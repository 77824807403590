<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">企業情報</h1>
      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">about</h2>
    </div>
  </div>
</template>


<style>
  #content a{ color:#631; text-decoration:underline; }
</style>


<script>
export default {}
</script>