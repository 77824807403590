<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">

      <!-- コレクション一覧 ==================================================================================================== -->

      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">コレクション一覧</h1>
      <div class="leading-none inline-block font-bold text-xl mb-4">評価額合計：{{getSum()}}円</div>
      <div v-if="collection!=null && collection.length==0">
        <p>まだ1枚も保有していません</p>
      </div>
      <section class="mb-8">
        <div class="flex flex-wrap bg-gray-200 pt-4 pr-4" style="border-radius:.25rem;">
          <!-- 繰り返し -->
          <div class="w-full lg:w-1/3 pl-4 mb-4" v-for="c in collection" :key="c.id">
            <div class="bg-white p-2 shadow" style="border-radius:.25rem;">
              <div class="mb-3">
                <a :href="c.nft.imageUrl" target="_blank"><img v-lazy="c.nft.imageUrl" class="w-full he-auto" style="border-radius:.125rem;"></a>
              </div>
              <div class="mb-3">
                <p class="block w-full text-lg leading-none font-bold text-center mb-2">
                  {{c.nft.ticker}}(NFT) {{c.balance}}枚
                </p>
                <p class="block w-full leading-none font-bold text-sm text-center">
                  出品可能:{{c.availableBalance}}
                </p>
              </div>
              <input type="checkbox" id="mona_sell" class="hidden" v-model="modalChecked">
              <label v-if="c.availableBalance>0" @click="selectTicker(c.nft.ticker)" for="mona_sell" class="mb-2 block w-full text-center font-medium text-base leading-none py-3 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.125rem;">
                出品する
              </label> 
              <div v-if="c.availableBalance==0" class="mb-2 block w-full text-center font-medium text-base leading-none py-3 bg-gray-200 text-gray-400" style="border-radius:.125rem;">
                出品する
              </div> 
              <div class="flex flex-wrap justify-between p-2 bg-gray-100 border-2">
                <div class="w-full text-xs font-bold mb-2">評価額計：</div>
                <div class="text-base font-bold leading-none">{{c.balance * c.lastPriceJpy}}円</div>
                <input :id="'valu_'+c.nft.ticker" type="checkbox" class="hidden">
                <label id="valu_label" :for="'valu_'+c.nft.ticker" class="cursor-pointer"><img src="/static/img/arrow.png" class="w-4 h-4" style="border-radius:.125rem;"></label>
                <div class="valu_box w-full">
                  <div class="border-t-2 pt-2 border-dotted border-gray-300 mt-2">
                    <button @click="selectWithdrawCoin()" class="block w-full font-bold leading-none text-sm py-2 border bg-white" style="border-radius:.125rem;">
                      <span class="withdraw_on">外部送付</span>
                    </button>
                  </div>
                </div>
              </div>

              <!-- 出品モーダルここから -->
              <div id="mona_sell_modal">
                <label for="mona_sell" class="absolute block w-full h-full cursor-pointer" style="z-index:-1;"></label>
                <div class="w-full mx-auto xl:w-1/2 bg-white p-8 max-h-full overflow-y-scroll" style="max-width:90%; max-height:90%; margin-top:5%; border-radius:.25rem;">
                  <div class="text-right"><label for="mona_sell" class="inline-block cursor-pointer"><img src="static/img/close.png" class="inline-block w-8 lg:w-16 h-8 lg:h-16"></label></div>
                  <div class="font-bold text-base">
                    <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                      出品価格
                      <button id="tippy" :content="tippy.price" v-tippy="{trigger : 'click'}">❔</button>
                    </h1>
                    <div class="flex mb-2">
                      <input type="text" oninput="value = value.replace(/[^0-9]+/i,'');" class="block text-right bg-gray-100 py-2 w-1/2 px-3 text-2xl lg:text-4xl font-black" 
                        v-model="form.price">
                      <div class="text-2xl lg:text-4xl font-black w-1/2 py-2 px-3">MONA</div>
                    </div>
                    <div class="flex mb-8">
                      <div class="leading-none text-gray-400 text-sm text-right w-1/2 pr-3">
                        ≒ {{Math.floor(monaPrice * form.price)}}
                      </div>
                      <div class="leading-none text-gray-400 text-sm w-1/2 pl-3">円</div>
                    </div>
                    <div class="block p-3 bg-yellow-100 text-yellow-500 leading-none mb-8">
                      マーケット最安価格: {{Number(bestAskPrice.value)}} MONA (≒{{bestAskPrice.jpy}}円)
                    </div>

                    <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                      受取モナコインアドレス
                      <button id="tippy" :content="tippy.address" v-tippy="{trigger : 'click'}">❔</button>
                    </h1>
                    <input type="text" placeholder="MONA アドレス" class="block w-full p-3 bg-gray-100 mb-8 font-bold truncate" v-model="form.address">

                    <h1 v-if="form.showForceUse" class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                      アドレスの強制使用
                    </h1>
                    <div v-if="form.showForceUse" class="mb-8">
                      <div class="flex">
                        <input id="check_000" type="checkbox" class="cursor-pointer" v-model="form.forceUseChecks" value="1" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                        <label for="check_000" class="inline-block leading-none cursor-pointer ml-2">
                          このアドレスは使用履歴が多いため、出品登録時の検証に時間がかかります。そのまま使用する場合はチェックを入れて再度出品ボタンを押してください。
                        </label>
                      </div>
                    </div>
                    
                    <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                      確認事項
                    </h1>
                    <div class="mb-8">
                      <div class="flex mb-4">
                        <input id="check_001" type="checkbox" class="cursor-pointer" v-model="form.checks" value="1" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                        <label for="check_001" class="inline-block leading-none cursor-pointer ml-2">
                          このモナコインアドレスは<a href="https://note.com/chocomnc/n/nb97572686209" target="_blank">取引所の受け取りアドレスではないことを誓います</a>。
                        </label>
                      </div>
                      <div class="flex mb-4">
                        <input id="check_002" type="checkbox" class="w-4 h-4 cursor-pointer" v-model="form.checks" value="2" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                        <label for="check_002" class="inline-block leading-none cursor-pointer ml-2">
                          このモナコインアドレスは自身の管理するウォレットで生成したものです。
                        </label>
                      </div>
                      <div class="flex">
                        <input id="check_003" type="checkbox" class="w-4 h-4 cursor-pointer" v-model="form.checks" value="3" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                        <label for="check_003" class="inline-block leading-none cursor-pointer ml-2">
                          <a href="/#/terms" target="_blank">利用規約</a>と<a href="https://note.com/chocomnc/n/nb97572686209" target="_blank">出品ガイド</a>の内容に同意します。
                        </label>
                      </div>
                    </div>
                    <div v-if="form.error.length > 0" class="block p-3 bg-red-100 text-red-500 leading-none mb-8">{{form.error}}</div>
                    <button v-if="!isLoadingForm" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;"
                      @click="goShuppin()"
                    >
                      {{selectedTicker}}(NFT) を出品する
                    </button> 
                    <button v-if="isLoadingForm" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                      <pulse-loader :loading="true" color="#ffffff"></pulse-loader>
                    </button>
                  </div>
                </div>
              </div>
              <!-- 出品モーダルここまで -->

            </div>
          </div>
          <!-- 繰り返しここまで -->
        </div>
      </section>

      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
        出品中
      </h1>
      <div class="overflow-x-scroll">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              NFT
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              出品価格(MONA)
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              受け取りアドレス
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              ステータス
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              アクション
            </th>
          </tr>
          <p v-if="sellOrders!=null && sellOrders.length==0">出品していません</p>
          <tr v-for="s in sellOrders" :key="s.id">
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              <router-link :to="{path: '/nft/'+s.ticker.toLowerCase()}">{{s.ticker}} (NFT)</router-link>
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{s.price}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{s.address}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{s.status}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              <button v-if="s.status=='出品中' && !showingLoading(s.id)" @click="cancellSell(s.id)" class="font-medium underline">キャンセル</button>
              <pulse-loader :loading="showingLoading(s.id)" color="#552712"></pulse-loader>
            </td>
          </tr>
        </table>
      </div>

      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
        外部送付履歴
      </h1>
      <div class="overflow-x-scroll">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              NFT
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              枚数
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              送付先アドレス
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              ステータス
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              日時
            </th>
          </tr>
          <tr v-for="w in withdrawHistories" :key="w.id">
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              <router-link :to="{path: '/nft/'+w.ticker.toLowerCase()}">{{w.ticker}} (NFT)</router-link>
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{w.amount}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{w.address}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{w.status}}
            </td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{w.date}}
            </td>
          </tr>
        </table>
      </div>



    </div>
  </div>
</template>




<style>
#mona_sell_modal{ position:fixed; overflow:hidden; z-index:9999; width:0; height:0; top:50%; left:50%; background-color:rgba(0,0,0,0); transition:background-color 0.5s; }
#mona_sell:checked ~ #mona_sell_modal{ width:100%; height:100%; top:0; left:0; background-color:rgba(0,0,0,0.9); transition:background-color 0.5s; }

.valu_box{ max-height:0; overflow:hidden; transition:max-height .25s; }
#valu_MONA:checked ~ .valu_box{ max-height:300px; transition:max-height .5s; }
#valu_LTC:checked ~ .valu_box{ max-height:300px; transition:max-height .5s; }
#valu_DOGE:checked ~ .valu_box{ max-height:300px; transition:max-height .5s; }
#valu_BTC:checked ~ .valu_box{ max-height:300px; transition:max-height .5s; }
</style>




<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { tippy } from "vue-tippy"

export default {
  components: {'PulseLoader': PulseLoader},
  data() {
    return {
      collection: null,
      sellOrders: null,
      withdrawHistories: null,
      modalChecked: false,
      monaPrice: 0,
      selectedTicker: "MONA", // modal押した際に代入される
      bestAskPrice: {
        value: null,
        jpy: null
      },
      tippy: {
        price: "整数のみ指定可能です。",
        address: "あなたが所有するモナコインアドレスを指定してください。落札者がこのアドレスに対して送金を行った後、売買が成立しNFTの所有権が落札者に移ります。"
      },
      form: {
        price: 100,
        address: "",
        error: "",
        checks: [],
        showForceUse: false, // アドレスの使用回数が多いものを強制的に使用するチェックボックスを表示するかどうか
        forceUseChecks: [], // アドレスの使用回数が多いものを強制的に使用するかどうか
      },
      isLoadingForm: false, // 出品時のローディング
      cancelLoadings: [] // 中身 {id: 3, loading: true}
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['getCollection', 'sellNft', 'getMySellOrders', 'cancellOrder', 'getBestAsk', 'getMonaRate', 'withdrawHistory']),
    // コレクション一覧と出品中を更新
    initData() {
      const thisObject = this
      this.getCollection().then(res => {
        thisObject.collection = res.data
      })
      this.getMySellOrders().then(res => {
        thisObject.sellOrders = res.data
        thisObject.cancelLoadings = res.data.map(a => { return {id: a.id, loading: false} })
      })
      this.getMonaRate().then(res => {
        thisObject.monaPrice = res.data.rate
      })
      this.withdrawHistory().then(res => {
        thisObject.withdrawHistories = res.data
      })
    },
    // 時価評価額合計
    getSum() {
      if(this.collection == null) return ""
      let sum = 0
      for(let i=0; i<this.collection.length; i++) {
        const c = this.collection[i]
        sum = sum + (c.balance * c.lastPriceJpy)
      }
      return sum
    },
    // 一覧で「出品する」ボタンを押した時
    selectTicker(ticker) {
      this.selectedTicker = ticker
      this.form.error = ""
      this.form.checks = []
      // 現在の最安値を取得
      const thisObject = this
      this.getBestAsk(this.selectedTicker).then(res => {
        thisObject.bestAskPrice.value = res.data.value
        thisObject.bestAskPrice.jpy = res.data.jpy
      }) 
    },
    // 外部送付を押したとき
    selectWithdrawCoin() {
      this.$router.push({path: "/withdraw"})
    },
    // 出品実行
    goShuppin() {
      if(this.form.checks.length < 3) {
        this.form.error = "出品するには全ての項目に同意してください"
        return 
      }
      const thisObject = this
      thisObject.isLoadingForm = true
      thisObject.form.error = ""
      const forceFlag = this.form.forceUseChecks.length > 0 ? true : false
      this.sellNft({ticker: this.selectedTicker, price: Number(this.form.price), 
        address: this.form.address, forceUseOfTooManyTxs: forceFlag}).then(res => {
        console.log("ok")
        thisObject.initData()
        thisObject.modalChecked = false
        thisObject.isLoadingForm = false
        thisObject.form.address = ""
        thisObject.form.checks = []
        thisObject.form.error = ""
      }).catch((e) => {
        if(e.message.match(/強制使用/)) {
          this.form.showForceUse = true
        }
        thisObject.form.error = e.message
        thisObject.isLoadingForm = false
      })
    },
    // 出品取り消し
    cancellSell(id) {
      const cancel = this.cancelLoadings.find(a => a.id == id)
      cancel.loading = true

      const thisObject = this
      this.cancellOrder({sellOrderId: id}).then(res => {
        cancel.loading = false
        thisObject.initData()
      }).catch(e => {
        cancel.loading = false
      })
    },
    // キャンセル時のローディングボタンを出すかどうか
    showingLoading(id) {
      return this.cancelLoadings.find(a => a.id == id).loading
    }
  }
}
</script>

<style scoped>
#tippy { border:0; background-color:#5f3619; color:#FFF; font-size:12px; display:inline-block; line-height:160%; text-align: center; margin-left:10px; border-radius:2px; }
</style>