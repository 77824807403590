import session from '../utilities/session'
import axios from '../utilities/axios'
import config from '../config'
import router from '../router'

import Swal from 'sweetalert2'
/* eslint-disable no-param-reassign */

export default {
  state: {
    auth: session.getAuth(),
    user: session.getUser(),
  },
  getters: {
    isAuthenticated(state) {
      return state.auth !== null && typeof state.auth !== 'undefined'
    },
    auth(state) {
      return state.auth
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    setAuthentication(state, obj) {
      if (obj) {
        state.auth = obj.token
        state.user = obj.user
        session.setAuthentication(obj)
      } else {
        state.auth = null
        state.user = null
        session.clear()
      }
    },
  },
  actions: {
    // APIサーバーからvueにリダイレクトしたあと、受け取ったデータをsetAuthenticationに流す
    loginWithTwitter(context, obj) {
      context.commit('setAuthentication', obj)
    },
    logout(context) {
      context.commit('setAuthentication')
      router.push({path: '/nft/mona'})
    },
    // 自分のNFT一覧を取得する
    getCollection(context) {
      return axios
        .get(`${config.api}/api/account/collection`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 出品中のNFT一覧を取得
    getMySellOrders(context) {
      return axios
        .get(`${config.api}/api/account/getSellOrders`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    }, 
    // 出品一覧から最安値を取得
    getBestAsk(context, obj) {
      return axios
        .get(`${config.api}/api/account/getBestAsk?ticker=${obj}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    }, 
    // 出品を取り消し
    cancellOrder(context, obj) {
      return axios
        .post(`${config.api}/api/account/cancellOrder`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // NFTを出品する
    sellNft(context, obj) {
      return axios
        .post(`${config.api}/api/account/sellNft`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 落札する
    buyNft(context, obj) {
      return axios
        .post(`${config.api}/api/account/buyNft`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 落札履歴
    buyHistory(context, obj) {
      return axios
        .get(`${config.api}/api/account/buyHistory?page=${obj.page}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 出品履歴
    sellHistory(context, obj) {
      return axios
        .get(`${config.api}/api/account/sellHistory?page=${obj.page}`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // エアードロップ履歴
    airdropHistory(context) {
      return axios
        .get(`${config.api}/api/account/airdropHistory`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 入金を手動でチェック
    checkDepositManually(context) {
      return axios
        .get(`${config.api}/api/account/checkDeposit`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 現在の設定を取得
    getCurrentSettings(context, obj) {
      return axios
        .get(`${config.api}/api/setting/currentSettings`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 自分のコレクションを公開するかどうかの切り替え
    changePublicCollection(context, obj) {
      return axios
        .post(`${config.api}/api/setting/changePublicCollection`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // メールを保存する
    saveMail(context, obj) {
      return axios
        .post(`${config.api}/api/setting/saveMail`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 通知を取得。取得すると同時に既読になる
    getNotification(context) {
      return axios
        .get(`${config.api}/api/account/notification`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 通知の数を取得
    getNotificationCount(context) {
      return axios
        .get(`${config.api}/api/account/notificationCount`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          //Swal.fire({icon: 'error', title: e.error, text: e.message})
          throw(e)
        })
    },
    // 出金申請
    withdraw(context, obj) {
      return axios
        .post(`${config.api}/api/account/withdrawRequest`, obj)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          throw(e)
        })
    },
    // 出金履歴
    withdrawHistory(context) {
      return axios
        .get(`${config.api}/api/account/withdrawHistory`)
        .then(res => { return res })
        .catch((error) => {
          const e = error.response.data
          throw(e)
        })
    },
    
  },
}
