<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">

      <!-- マーケット ==================================================================================================== -->

      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
        ICL (Initial Chocomonaca Lottely)
      </h1>
      <p class="text-base lg:text-2xl font-bold mb-8 lg:mb-16">
        チョコモナカに上場するNFT第一弾はモナコインをモチーフにしたものです。<br />
        このNFTは抽選形式で配布いたします。（実質無料）
      </p>
      <div class="text-center mb-8 lg:mb-16"><img src="static/img/mona_prod.png" class="inline-block w-full md:w-1/2" style="border-radius:.25rem;"></div>
      <p>参加受付は終了しました</p>
      <!-- <input type="checkbox" id="mona_sell" class="hidden">
      <label v-if="isAuthenticated" for="mona_sell" class="block w-full text-center font-medium text-2xl leading-none py-8 cursor-pointer mb-8" style="color:#FFC; background-color:#630; border-radius:.125rem;"
        @click="getAddress()"
      >
        抽選に参加する
      </label> 
      <label v-if="!isAuthenticated" class="block w-full text-center font-medium text-2xl leading-none py-8 cursor-pointer mb-8" style="color:#FFC; background-color:#630; border-radius:.125rem;"
        @click="loginByTwitter()"
      >
        Twitterログイン
      </label>  -->
      <div id="mona_sell_modal">
        <div class="p-8 lg:p-16">
          <div class="w-full xl:w-1/2 mx-auto bg-white p-8" style="border-radius:.25rem;">
            <div class="text-right"><label for="mona_sell" class="inline-block cursor-pointer"><img src="static/img/close.png" class="inline-block w-8 lg:w-16 h-8 lg:h-16"></label></div>
            <div class="font-bold text-base">
              <div class="text-center mb-8">
                <a href="#" @click="showHideQR()">
                  <vue-qrcode v-if="qrString && showQR" class="inline-block w-64 h-64" :value="qrString" />
                  <div v-if="showQR">QRコードを隠す</div>
                  <div v-if="!showQR">QRコードを表示</div>
                </a>
              </div>

              <div class="block w-full text-center text-xl font-bold p-3 bg-gray-100 mb-8">
                {{qrString}}
                <button id="tippy" :content="tippy.copyAddress" v-tippy="{trigger : 'click'}" class="clipcopy" v-bind:data-clipboard-text="qrString">&nbsp;コピー&nbsp;</button>
              </div>

              <div class="text-xl text-center font-bold mb-8">
                <span v-html="depositInfo.msg"></span>
                <!-- {{depositInfo.msg}} -->
              </div>
              <button v-if="!depositInfo.loading && !depositInfo.isSent" @click="checkDeposit()" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                送金チェック
              </button>

              <a :href="getShareUrl()" v-if="!depositInfo.loading && depositInfo.isSent" class="inline-block w-full text-center font-medium text-sm leading-none py-2 px-3" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;" target="_blank">シェアする</a>

              <button v-if="depositInfo.loading" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                <pulse-loader :loading="true" color="#ffffff"></pulse-loader>
              </button> 
            </div>
          </div>
        </div>
      </div>
      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">ＩＣＬ概要</h2>
      <div class="overflow-x-scroll mb-8">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">NFT名</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">Monacoin</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">総発行枚数</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">105枚（追加発行不可）</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">NFT情報</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;"><a href="https://mpchain.info/asset/CHOCOMONACA.MONA" target="_blank">URLを開く</a></td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">NFT画像</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;"><a href="https://cloudflare-ipfs.com/ipfs/QmdJx87NEusFD3zbYKUqP1Lt9vpzWioFJZRniMBZEiNTNh" target="_blank">URLを開く</a></td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">当選者数</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">100名（<a href="https://twitter.com/chocomnc/status/1379363746582126597">リツイートキャンペーン</a>で5名、合計105名）</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">運営者への割当</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">0枚</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">抽選参加費</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">0.01 MONA（約2円）</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">抽選日</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">2021年4月18日</td>
          </tr>
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap text-right" style="color:630;">上場予定日</th>
            <td class="w-2/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">2021年4月20日</td>
          </tr>
        </table>
      </div>
      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">備考</h2>
      <p class="text-base lg:text-xl font-bold mb-0">
        ・参加費0.01MONAが必要な理由は、いたずらによる参加を防ぐためです。また、モナコインを利用している方にNFTを保有して頂きたいという思いもあります。<br />
        ・0.01MONAは現在価格で2円程度なので、実質的に無償での配布となります。<br />
        ・集めたMONAは抽選終了後に全てバーンいたします。<br />
        ・チョコモナカでは今後もNFTを無償配布していきます。
      </p>
    </div>
  </div>
</template>




<style>
#mona_bid_modal{ position:fixed; overflow:hidden; z-index:9999; width:0; height:0; top:50%; left:50%; background-color:rgba(0,0,0,0); transition:background-color 0.5s; }
#mona_bid:checked ~ #mona_bid_modal{ width:100%; height:100%; top:0; left:0; background-color:rgba(0,0,0,0.9); transition:background-color 0.5s; }
#openbox{ max-height:0; overflow:hidden; transition:max-height .25s; }
#open:checked ~ #openbox{ max-height:500px; transition:max-height .25s; }
</style>




<script>
import config from "../../config"
import session from '../../utilities/session'
import { mapActions } from 'vuex'
import store from '../../store'
import { tippy } from "vue-tippy"
import VueQrcode from 'vue-qrcode'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import clipboard from 'clipboard'

export default {
  components: { VueQrcode , 'PulseLoader': PulseLoader},
  data() {
    return {
      qrString: "",
      showQR: true,
      depositInfo: {
        loading: false,
        msg: "上記のアドレスに0.01MONAを送ると<br />抽選に参加できます",
        isSent: false,
        lastPushed: null
      },
      tippy: {
        copyAddress: "アドレスをコピーしました",
      }
    }
  },
  mounted() {
    this.clipBoard = new clipboard('.clipcopy')
    this.clipBoard.on('success', function(e) {     
      e.clearSelection()
    })
    this.clipBoard.on('error', function(e) {
    })
  },
  methods: {
    ...mapActions(['getIclAddress', 'sendCheck']),
    // ログイン
    loginByTwitter() {
      session.setLastPath(this.$route.path)
      window.location = config.twitterLoginUrl
    },
    showHideQR() {
      this.showQR = !this.showQR
    },
    // 自分のアドレスを取得
    getAddress() {
      const thisObject = this
      this.getIclAddress().then(res => {
        thisObject.qrString = res.data.address
        thisObject.depositInfo.isSent = res.data.isSent
        if(res.data.isSent) thisObject.depositInfo.msg = "送金が検出されました。抽選に参加済みです🎉"
      })
    },
    // 送金チェック
    checkDeposit() {
      const date = new Date() 
      const now = Math.floor(date.getTime() / 1000)
      if(now - this.depositInfo.lastPushed < 10) {
        this.depositInfo.msg = "押しすぎです。10秒待ってください🙆‍♀️"
        return
      }

      const thisObject = this
      thisObject.depositInfo.loading = true
      this.sendCheck().then(res => {
        if(res.data.result) {
          thisObject.depositInfo.msg = "送金が検出されました。抽選に参加済みです🎉"
          thisObject.depositInfo.isSent = true
        } else {
          thisObject.depositInfo.msg = "送金は検出されませんでした"
        }
        thisObject.depositInfo.loading = false
      }).catch((e) => {
        thisObject.depositInfo.loading = false
      })
      thisObject.depositInfo.lastPushed = now
    },
    getShareUrl() {
      const title = "NFTの抽選に参加しました！ %23chocomonaca"
      const url = "https://chocomonaca.com"
      return 'https://twitter.com/intent/tweet?text=' + title  + '&url=' + url; 
    }
  }
}
</script>

<style scoped>
#tippy { border:0; background-color:#5f3619; color:#FFF; font-size:12px; display:inline-block; line-height:160%; text-align: center; margin-left:10px; border-radius:2px; }
</style>