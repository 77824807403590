<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">@{{twitterName}}のコレクション一覧</h1>
      <p v-if="balances!=null && balances.length==0">コレクションがありません</p>
      <div class="flex flex-wrap bg-gray-200 pt-4 pr-4" style="border-radius:.25rem;">
        <div class="w-full lg:w-1/3 pl-4 mb-4" v-for="b in balances" :key="b.nft.id">
          <div class="bg-white p-2 shadow" style="border-radius:.25rem;">
            <div class="mb-3">
              <router-link :to="'/nft/' + b.nft.ticker.toLowerCase()"><img :src="b.nft.imageUrl" class="w-full he-auto" style="border-radius:.125rem;"></router-link>
            </div>
            <div class="mb-3">
              <p class="block w-full text-lg leading-none font-bold text-center">
                {{b.nft.ticker}} {{b.balance}}枚
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../../store'

export default {
  data() {
    return {
      id: this.$route.params.id,
      twitterName: "",
      balances: null
    }
  },
  mounted() {
    const thisObject = this
    this.getPublicNftCollection(this.id).then(res => {
      thisObject.twitterName = res.data.twitterName
      thisObject.balances = res.data.balances
    })
  },
  methods: {
    ...mapActions(["getPublicNftCollection"]),

  }
}
</script>