<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">設定</h1>
      <div class="overflow-x-scroll">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
              Twitterアカウント
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              @{{this.user.twitterScreenName}}
            </td>
          </tr>
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
              アカウントステータス
              <button id="tippy" :content="tippy.lock" v-tippy="{trigger : 'click'}">❔</button>
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              {{getLockStatus()}}
            </td>
          </tr>
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 p-3 text-base font-semibold text-right align-top" style="color:630; min-width:6rem;">
              コレクションの公開
              <button id="tippy" :content="tippy.public" v-tippy="{trigger : 'click'}">❔</button>
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <input type="checkbox" value="1" v-model="publicCollection" @change="toggleShowCollection()" class="w-8 h-8 mb-3">
              <span v-if="publicCollection" class="w-full block mb-3">
                <router-link :to="{path:'/user/'+user.id}">https://chocomonaca.com/#/user/{{user.id}}</router-link>
              </span>
              <div v-if="publicCollection">
                <a :href="getShareUrl()" target="_blank" class="inline-block font-medium text-sm leading-none py-2 px-3 text-center" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;">シェアする</a>
              </div>
            </td>
          </tr>
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 p-3 text-base font-semibold text-right align-top" style="color:630; min-width:6rem;">
              メール通知
              <button id="tippy" :content="tippy.mail" v-tippy="{trigger : 'click'}">❔</button>
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <input v-model="mailAddress" type="text" placeholder="メールアドレス" value="" class="block w-full p-3 bg-gray-100 mb-3 font-bold truncate">
              <button @click="saveMailToServer()" class="inline-block font-medium text-sm leading-none py-2 px-3" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;">保存</button>
            </td>
          </tr>
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <button @click="confirmLogout()" class="inline-block font-medium text-sm leading-none py-2 px-3" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;">ログアウト</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex'
import store from '../../store'
import { tippy } from "vue-tippy"

export default {
  data() {
    return {
      publicCollection: null,
      lockedUntil: null,
      mailAddress: "",
      tippy: {
        mail: "出品したNFTが落札された際にメールで通知します。設定しない場合は文字をすべて消して保存してください。",
        public: "チェックを入れると、あなたのコレクション一覧が下記のURLから誰でも閲覧可能になります。",
        lock: "アカウントがロックされると新規の落札・出品ができません。"
      }
    }
  },
  mounted() {
    const thisObject = this
    this.getCurrentSettings().then(res => {
      thisObject.publicCollection = res.data.publicCollection
      thisObject.lockedUntil = res.data.lockedUntil
      thisObject.mailAddress = res.data.mail
    })
  },
  methods: {
    ...mapActions(['logout', 'getCurrentSettings', 'changePublicCollection', 'saveMail']),
    // 公開・非公開設定の切り替え
    toggleShowCollection() {
      const thisObject = this
      this.changePublicCollection({value: this.publicCollection}).then(res => {
        const msg = this.publicCollection ? "公開しました" : "非公開にしました"
        thisObject.$toasted.show(msg, { 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        });
      })
    },
    // アカウントステータス文字列
    getLockStatus() {
      if(this.lockedUntil == null) return ""
      else if(this.lockedUntil == false) return "正常"
      else return "ロック中(" + this.lockedUntil +"まで)"
    },
    getShareUrl(){
      const title = "私のNFTコレクションです！ %23chocomonaca"
      const url = "https://chocomonaca.com/%23/user/" + this.user.id
      return 'https://twitter.com/intent/tweet?text=' + title  + '&url=' + url;  
    },
    // メール通知保存
    saveMailToServer() {
      const thisObject = this
      this.saveMail({mail: this.mailAddress}).then(res => {
        thisObject.$toasted.show("保存しました", { 
          theme: "toasted-primary", position: "bottom-center", duration : 2000
        });
      })
    },
    // ログアウト
    confirmLogout() {
      swal({
        title: 'ログアウトしますか？',
        text: '',
        icon: 'warning',
        buttons: {
          cancel: { text: 'キャンセル', visible: true},
          confirm: { text: 'はい'},
        },
        dangerMode: true,
      }).then(confirm => {
        if (confirm) { this.logout() }
      })
    },
  }
}
</script>

<style scoped>
#tippy { border:0; background-color:#5f3619; color:#FFF; font-size:12px; display:inline-block; line-height:160%; text-align: center; margin-left:10px; border-radius:2px; }

</style>