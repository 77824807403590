import vue from 'vue'
import VueRouter from 'vue-router'
import index from "../views/index.vue"

// private
import store from '../store'
import collection from '../views/account/collection.vue'
import setting from '../views/account/setting.vue'
import withdraw from '../views/account/withdraw.vue'
import history from "../views/account/history.vue"
import notification from "../views/account/notification.vue"

// event
import lottely from "../views/event/lottely.vue" 

// public
import user from "../views/nft/user.vue"
import nft from "../views/nft/nft.vue"
import chart from "../views/nft/chart.vue"

// guide
import about from '../views/guide/about.vue'
import terms from '../views/guide/terms.vue'
import fee from '../views/guide/fee.vue'

// error
import notFound from '../views/error/notFound.vue'

import twitterCallback from "../views/account/twitterCallback.vue"

vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  root: '/',
  routes: [
    {
      path: '/',
      component: index,
      meta: {
        isPublic: true,
      }
    },
    {
      path: '/auth/twitterCallback',
      component: twitterCallback,
      meta: {
        skipIfAuthorized: true,
        isPublic: true,
      }
    },
    // コレクション一覧
    {
      path: '/collection',
      component: collection
    },
    // 設定
    {
      path: '/setting',
      component: setting
    },
    // 外部送付
    {
      path: '/withdraw',
      component: withdraw
    },
    // 落札・出品履歴
    {
      path: "/history",
      component: history
    },
    // 通知
    {
      path: "/notification",
      component: notification
    },
    // 公開ページ
    {
      path: '/user/:id',
      component: user,
      meta: {
        isPublic: true,
      },
    },
    // ICL
    {
      path: "/lottely",
      component: lottely,
      meta: {
        isPublic: true,
      },
    },
    // チャート
    {
      path: "/chart/:ticker",
      component: chart,
      meta: {
        isPublic: true,
      },
    },
    // NFT画面
    {
      path: '/nft/:ticker',
      component: nft,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/about',
      component: about,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/terms',
      component: terms,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/fee',
      component: fee,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/auth/logout',
    },
    {
      path: '*',
      component: notFound,
      meta: {
        isPublic: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.commit('hideHeader') // ヘッダーを消す
  
  if (store.getters.isAuthenticated) {
    if (to.path === '/auth/logout') {
      store.dispatch('logout')
    } else if (to.matched.some(record => record.meta.skipIfAuthorized)) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.isPublic)) {
    next()
  } else {
    next({
      path: '/nft/mona',
      // query: {
      //   redirect: to.fullPath,
      // },
    })
  }
})

export default router
