import vue from 'vue'
import vuex from 'vuex'

import userModule from './user'
import stateModule from './state'
import nftDataModule from "./nftData"
import eventModule from "./event"

vue.use(vuex)

const store = new vuex.Store({
  modules: {
    user: userModule,
    state: stateModule,
    nftData: nftDataModule,
    event: eventModule
  },
})

export default store
