<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
        チャート(円建て)<router-link :to="'/nft/' + select.ticker.toLowerCase()">📖</router-link>
      </h1>
      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        <select v-model="select.ticker" @change="onChangeTicker($event)" name="ticker">
          <option value="MONA">MONA(NFT)</option>
          <option value="DOGE">DOGE(NFT)</option>
          <option value="BTC">BTC(NFT)</option>
        </select>
        <select v-model="select.period" @change="onChangePeriod($event)" name="period">
          <option value="90">直近90日間</option>
          <option value="30">直近30日間</option>
          <option value="1">直近24時間</option>
        </select>
      </h2>
      <clip-loader :loading="isLoadingChart" color="#000000"></clip-loader>
      
      <div class="overflow-x-scroll mb-2">
        <div>
          <div id="tradingview" ref="chart"></div>
        </div>
      </div>

      <br /><br />

      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">最新約定履歴</h1>

      <div class="overflow-x-scroll mb-2">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              銘柄
            </th>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              約定価格(MONA)
            </th>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              円換算
            </th>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              日時
            </th>
          </tr>
          <tr v-for="history in tradeHistory" :key="history.id">
            <td class="w-1/4 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.nft}}(NFT)
            </td>
            <td class="w-1/4 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.price}}
            </td>
            <td class="w-1/4 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.jpyValue}}
            </td>
            <td class="w-1/4 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.time}}
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex'
import store from '../../store'
import Swal from 'sweetalert2'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { createChart } from 'lightweight-charts';

export default {
  components: { ClipLoader },
  data() {
    return {
      select: {
        ticker: this.$route.params.ticker.toUpperCase(),
        period: 30,
      },
      chartJs: {
        datacollection: null,
        options: null
      },
      isLoadingChart: true,
      chartData: [],
      chart: null,
      tradeHistory: []
    }
  },
  mounted() {
    const thisObject = this
    this.isValidTicker(this.select.ticker).then(res => {
      if(!res.data.result) {
        Swal.fire({icon: 'error', title: "存在しないティッカーです", text: ""})
      } else {
        thisObject.nft = res.data.nft
        thisObject.initData()
      }
    })
    
  },
  methods: {
    ...mapActions(['isValidTicker', 'getChart', 'getTradeHistory']),
    initData() {
      // チャート
      const thisObject = this
      this.getChart({ticker: this.select.ticker, period: this.select.period}).then(res => {
        thisObject.chartData = res.data
        thisObject.isLoadingChart = false
        //thisObject.fillChart()
        thisObject.fillTradingView()
      })
      this.getTradeHistory({ticker: this.select.ticker}).then(res => {
        thisObject.tradeHistory = res.data
        console.log(res.data)
      })
    },
    // ティッカーが変更された
    onChangeTicker(event) {
      this.select.ticker = event.target.value
      this.changeTickerPeriod()
    },
    // 期間が変更された
    onChangePeriod(event) {
      this.select.period = event.target.value
      this.changeTickerPeriod()
    },
    changeTickerPeriod() {
      this.isLoadingChart = true
      const thisObject = this
      this.getChart({ticker: this.select.ticker, period: this.select.period}).then(res => {
        thisObject.chartData = res.data
        // thisObject.fillChart()

        var tradingviewElement = document.getElementById("tradingview")
        tradingviewElement.removeChild(tradingviewElement.firstElementChild)

        thisObject.fillTradingView()
        thisObject.isLoadingChart = false
      }).catch(e => {
        thisObject.isLoadingChart = false
      })
      this.getTradeHistory({ticker: this.select.ticker}).then(res => {
        thisObject.tradeHistory = res.data
        console.log(res.data)
      })
    },
    // resize(width, height) {
		// 	this.chart.resize(width, height);
		// }
    // fillChart () {
    //   this.chartJs.datacollection = {
    //     datasets: [
    //       {
    //         label: '日本円換算額',
    //         backgroundColor: '#663311',
    //         fill: true,
    //         borderColor: 'rgb(102,51,17)',
    //         data: this.chartData
    //       }
    //     ]
    //   }
    //   this.chartJs.options = {
    //     responsive: true,
    //     scales: {
    //       xAxes: [{
    //         type: 'time',
    //         distribution: 'linear',
    //         time: { 
    //           unit: 'day',
    //         }
    //       }]
    //     }
    //   }
    // },
    fillTradingView() {
      const thisObject = this
      // const chart = createChart(document.getElementById("tradingview"), 
      //   { width: window.innerWidth*0.7, height: window.innerWidth*0.7 / 4 * 3 });
      
      var tradingviewElement = document.getElementById("tradingview")
      this.chart = createChart(tradingviewElement, {
        width: this.$refs.chart.innerWidth ,
        // width: this.$refs.chart.innerWidth,
        height: 400,
        // height: this.$refs.chart.innerHeight,
        rightPriceScale: {
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
        },
      });
      // document.body.appendChild(chartElement);
      // document.body.appendChild(switcherElement);

      // const ro = new ResizeObserver((entries) => {
      //   const cr = entries[0].contentRect;
      //   thisObject.resize(cr.width, cr.height);
      // });
      // ro.observe(this.$refs.chart);

      // window.addEventListener("resize", () => {
      //   thisObject.resize(thisObject.$refs.chart.innerWidth, thisObject.$refs.chart.innerHeight);
      // });

      var areaSeries = this.chart.addAreaSeries({
        topColor: 'rgba(33, 150, 243, 0.56)',
        bottomColor: 'rgba(33, 150, 243, 0.04)',
        lineColor: 'rgba(33, 150, 243, 1)',
        lineWidth: 2,
      });
        
      const lightTheme = {
        chart: {
          layout: {
            backgroundColor: '#FFFFFF',
            lineColor: '#2B2B43',
            textColor: '#191919',
          },
          watermark: {
            color: 'rgba(0, 0, 0, 0)',
          },
          grid: {
            vertLines: {
              visible: false,
            },
            horzLines: {
              color: '#f0f3fa',
            },
          },
        },
        series: {
            topColor: 'rgba(96, 54, 25, 0.56)',
            bottomColor: 'rgba(255, 255, 255, 0.04)',
            lineColor: 'rgba(96, 54, 25, 1)',
        },
        timeScale: {
          timeVisible: true,
        }
      };

      var themesData = {
        Light: lightTheme,
      };

      function syncToTheme(theme) {
        thisObject.chart.applyOptions(themesData[theme].chart);
        areaSeries.applyOptions(themesData[theme].series);
      }

      areaSeries.setData(
        thisObject.chartData
      );
      syncToTheme('Light');
      this.chart.timeScale().fitContent()

    } // end fillTradingView
  
  }
}
</script>