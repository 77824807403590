<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">外部送付</h1>
      <div class="overflow-x-scroll">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
              送付するNFT
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <select v-model="form.ticker" @change="onChangeTicker($event)" name="ticker">
                <option value="">選択してください</option>
                <option value="MONA">MONA(NFT)</option>
                <option value="DOGE">DOGE(NFT)</option>
                <option value="BTC">BTC(NFT)</option>
              </select>
            </td>
          </tr>

          <tr>
            <th class="w-1/4 border-2 bg-gray-100 p-3 text-base font-semibold text-right align-top" style="color:630; min-width:6rem;">
              送付先アドレス
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <input v-model="form.address" type="text" placeholder="モナコインアドレス" value="" class="block w-full p-3 bg-gray-100 mb-3 font-bold truncate">
            </td>
          </tr>

          <tr>
            <th class="w-1/4 border-2 bg-gray-100 p-3 text-base font-semibold text-right align-top" style="color:630; min-width:6rem;">
              枚数
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <input v-model="form.amount" type="number" placeholder="数量" value="" class="block w-full p-3 bg-gray-100 mb-3 font-bold truncate">
              最大送付可能枚数：　{{withdrawableAmount}} 枚
            </td>
          </tr>

          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
              注意事項
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              ・送付の実行には数営業日かかる場合があります。<br />
              ・送付申請後、キャンセルは出来ません。<br />
              ・送付先のアドレスに関して、当サービスでは一切の責任を持てません。<br />
              ・NFTの入庫機能を実装する予定はありません。<br />
              ・外部送付後は外部のサービスでのみしか利用できません。<br />
            </td>
          </tr>

          <tr>
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630;">
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              <div v-if="form.error.length > 0" class="block p-3 bg-red-100 text-red-500 leading-none mb-8">
                {{form.error}}
              </div>

              <button v-if="!loading" @click="execute()" class="inline-block font-medium text-sm leading-none py-2 px-3" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;">
                送付申請
              </button>
              <button v-if="loading" class="inline-block font-medium text-sm leading-none py-2 px-3" style="color:#FFC; text-decoration:none; background-color:#630; border-radius:.25rem; min-width:150px;">
                <pulse-loader :loading="true" color="#ffffff"></pulse-loader>
              </button>
            </td>
          </tr>
        </table>
      </div>

      <br /><br />
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">外部送付履歴</h1>
      <div class="overflow-x-scroll">
        <p><router-link to="/collection">コレクションページ</router-link>をご覧ください</p>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex'
import store from '../../store'
import { tippy } from "vue-tippy"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
  components: {'PulseLoader': PulseLoader},
  data() {
    return {
      form: {
        ticker: "",
        address: "",
        amount: 0,
        error: ""
      },
      collection: null,
      loading: false,
      withdrawableAmount: "-",
      tippy: {
        mail: "",
      }
    }
  },
  mounted() {
    const thisObject = this
  },
  methods: {
    ...mapActions(['getCollection', 'withdraw']),

    // ティッカーが変更された
    onChangeTicker(event) {
      this.form.ticker = event.target.value
      this.getWithdrawableAmount(event.target.value)
    },

    // 自分のコインの数量を取得する
    getWithdrawableAmount(ticker) {
      const thisObject = this
      thisObject.withdrawableAmount = "..."
      if(ticker) {
        // 数量取得
        this.getCollection().then(res => {
          for(let i=0; i<res.data.length; i++) {
            const balance = res.data[i]
            if(balance.nft.ticker == ticker) {
              thisObject.withdrawableAmount = balance.availableBalance
              break
            }
          }
        })
      } else {
        this.withdrawableAmount = "-"
      }
    },

    // 出金申請
    execute() {
      if(this.form.ticker == "") { this.form.error = "NFTを選択してください"; return; }
      if(this.form.address == "") {this.form.error = "アドレスを指定してください"; return; }
      if(this.form.amount < 1) {   this.form.error = "数量は1以上を指定してください"; return; }

      this.form.error = ""
      const text = "NFT: " + this.form.ticker + ", 枚数: " + this.form.amount + "枚"
      const thisObject = this
  
      swal({
        title: '送付申請を行います',
        text: text,
        icon: 'warning',
        buttons: {
          cancel: { text: 'キャンセル', visible: true},
          confirm: { text: '送付する'},
        },
        // dangerMode: true,
      }).then(confirm => {
        if (confirm) { 
          // API実行
          thisObject.loading = true
          thisObject.withdraw({
            ticker: this.form.ticker, address: this.form.address, amount: this.form.amount}
          ).then(res => {
            thisObject.loading = false
            this.$router.push({path: "/collection"})
          }).catch(e => {
            thisObject.form.error = e.message
            thisObject.loading = false
          })

        }
      })
    },
  }
}
</script>

<style scoped>
#tippy { border:0; background-color:#5f3619; color:#FFF; font-size:12px; display:inline-block; line-height:160%; text-align: center; margin-left:10px; border-radius:2px; }

</style>