/* eslint-disable no-param-reassign */
import session from '../utilities/session'

export default {
  state: {
    loading: false,
    quotes: session.getQuotes(),
    quote: null,
    isShowHeader: false
  },
  getters: {
    showingHeader(state) {
      return state.isShowHeader
    },
  },
  mutations: {
    hideHeader(state) {
      state.isShowHeader = false
    },
    showHeader(state) {
      state.isShowHeader = true
    },
  },
  actions: {},
}
