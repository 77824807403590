<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">手数料</h1>
      <div class="overflow-x-scroll">
        <tr>
          <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630; min-width:200px;">
            NFT売買手数料
          </th>
          <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
            しばらく無料（終了2週間前に告知します）
          </td>
        </tr>
        <tr>
          <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630; min-width:200px;">
            NFT送付手数料
          </th>
          <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
            無料
          </td>
        </tr>  
      </div>
    </div>
  </div>
</template>


<style>
  #content a{ color:#631; text-decoration:underline; }
</style>


<script>
export default {}
</script>