<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
    <h2> Not Found!</h2>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang='stylus'>
</style>
