<template>
  <div>
    <div class="lg:flex bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <div class="lg:w-3/5 lg:pr-4 mb-8 lg:mb-0">
        <div class="text-center bg-gray-200 p-4">
          <div class="w-full lg:w-2/3 relative inline-block p-2 bg-white shadow" style="border-radius:.25rem;">
            <select v-model="selectedTicker" name="ticker" @change="selectClick($event)" class="absolute z-50 right-0 top-0 mt-4 mr-4 md:mt-6 md:mr-6 bg-white leading-none font-bold">
              <option value="mona">Ticker：MONA</option>
              <option value="doge">Ticker：DOGE</option>
              <option value="btc">Ticker：BTC</option>
            </select>
            <img v-lazy="nft.imageUrl" class="inline-block w-full h-auto" style="border-radius:.25rem;">
          </div>
        </div>
      </div>
      <div class="lg:w-2/5 font-bold lg:pl-4">
        <h1 class="leading-none text-2xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
          {{nft.name}}(NFT)
          <router-link :to="'/chart/' + ticker.toLowerCase()">📊</router-link>
        </h1>
        <div class="flex flex-wrap">
          <input type="radio" id="bid_001" name="bid_group" class="hidden" checked>
          <input type="radio" id="bid_002" name="bid_group" class="hidden">
          <input type="radio" id="bid_003" name="bid_group" class="hidden">
          <label for="bid_001" id="bid_001_label" class="block w-1/3 mb-4 py-3 text-center border-t-2 border-r border-l-2 cursor-pointer" style="border-radius:.25rem 0 0 0">
            出品一覧
          </label>
          <label for="bid_002" id="bid_002_label" class="block w-1/3 mb-4 py-3 text-center border-t-2 border-r-2 border-l cursor-pointer" style="border-radius:0 .25rem 0 0">
            情報
          </label>
          <label for="bid_003" id="bid_003_label" class="block w-1/3 mb-4 py-3 text-center border-t-2 border-r-2 border-l cursor-pointer" style="border-radius:0 .25rem 0 0">
            公開保有者
          </label>
          <div id="bid_001_box" class="w-full">
            <p><a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank">📙 落札ガイドを必ずご覧ください</a></p>
            <div class="overflow-x-scroll">
              <table class="w-full border-collapse">
                <tr>
                  <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold whitespace-nowrap" style="color:630;">
                    価格(MONA)
                  </th>
                  <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold whitespace-nowrap" style="color:630;">
                    円換算
                  </th>
                  <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold whitespace-nowrap" style="color:630;">
                  </th>
                </tr>

                <!-- 繰り返しここから  -->
                <tr v-for="(order, index) in realTimeSellOrders" :key="order.id">
                  <td class="w-1/3 border-2 leading-none p-3 text-sm font-medium whitespace-nowrap" style="color:630;">
                    {{order.price}}
                  </td>
                  <td class="w-1/3 border-2 leading-none p-3 text-sm font-medium whitespace-nowrap" style="color:630;">
                      {{monaJpyPrice != null ? Math.floor(order.price * monaJpyPrice) : ""}}円
                  </td>
                  <td class="w-1/3 border-2 leading-none p-3 text-sm font-medium whitespace-nowrap" style="color:630;">
                    <input type="checkbox" id="mona_bid" class="hidden">
                    <label v-if="index<3 && isAuthenticated" for="mona_bid" class="block w-full text-sm whitespace-nowrap font-medium py-2 px-3 text-center cursor-pointer" style="color: rgb(255, 255, 204); background-color: rgb(102, 51, 0); border-radius:.125rem;"
                      @click="prepareBuy(order)"
                    >
                      落札
                    </label>
                    <div v-if="index==0 && !isAuthenticated" class="block w-full text-sm whitespace-nowrap font-medium py-2 px-3 text-center cursor-pointer bg-gray-200 text-gray-400" style="border-radius:.125rem;"
                      @click="loginByTwitter()"
                    >
                      落札（ 要ログイン ）
                    </div>

                    <!-- モーダルここから -->
                    <div id="mona_bid_modal">
                      <label for="mona_bid" class="absolute block w-full h-full cursor-pointer" style="z-index:-1;"></label>
                      <div class="w-full mx-auto xl:w-1/2 bg-white p-8 max-h-full overflow-y-scroll" style="max-width:90%; max-height:90%; margin-top:5%; border-radius:.25rem;">
                        <div class="text-right"><label for="mona_bid" class="inline-block cursor-pointer"><img src="static/img/close.png" class="inline-block w-8 lg:w-16 h-8 lg:h-16"></label></div>
                        <div class="font-bold text-base">
                          <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                            確認事項
                          </h1>
                          <div class="mb-8">
                            <div class="flex mb-4">
                              <input id="check_002" type="checkbox" class="inline-block cursor-pointer" v-model="form.checks" value="2" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                              <label for="check_002" class="inline-block leading-none cursor-pointer ml-2">
                                自身で管理しているウォレットを所有しており、落札価格と必要なネットワーク手数料の合計値以上の残高を保有しています. <a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank"><div style="color:red">取引所から直接出金申請しても落札できないことを理解しています.&nbsp;</div></a>
                              </label>
                            </div>
                            <div class="flex mb-4">
                              <input id="check_001" type="checkbox" class="inline-block cursor-pointer" v-model="form.checks" value="1" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                              <label for="check_001" class="inline-block leading-none cursor-pointer ml-2">
                                落札後、送金する数量を間違えると取引は完了しません<a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank">(資金を失います)</a>.&nbsp;
                              </label>
                            </div>
                            <div class="flex mb-4">
                              <input id="check_003" type="checkbox" class="inline-block cursor-pointer" v-model="form.checks" value="3" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                              <label for="check_003" class="inline-block leading-none cursor-pointer ml-2">
                                落札後、60分以内にブロックチェーン上で送金が確認できない場合、7日間アカウントがロックされます.&nbsp;
                              </label>
                            </div>
                            <div class="flex">
                              <input id="check_004" type="checkbox" class="inline-block cursor-pointer" v-model="form.checks" value="4" style="width:1rem; height:1rem; min-width:1rem; min-height:1rem;">
                              <label for="check_004" class="inline-block leading-none cursor-pointer ml-2">
                                <a href="/#/terms" target="_blank">利用規約</a>と<a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank">落札ガイド</a>の内容に同意します.
                              </label>
                            </div>
                          </div>
                          <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">詳細</h1>
                          <div class="bg-gray-100 p-4 lg:p-8 mb-8">
                            <div class="lg:flex lg:justify-between border-b-2 border-dotted border-gray-300 pb-4 mb-4">
                              <div class="text-gray-400 mb-2 lg:mb-0 text-base lg:text-2xl leading-none font-medium">
                                落札価格
                              </div>
                              <div class="text-base lg:text-2xl leading-none font-bold">
                                {{selected.price}} MONA&nbsp;
                              </div>
                            </div>
                            <div class="lg:flex lg:justify-between">
                              <div class="text-gray-400 mb-2 lg:mb-0 text-base lg:text-2xl leading-none font-medium">システム手数料</div>
                              <div class="text-base lg:text-2xl leading-none font-bold">0 MONA&nbsp;</div>
                            </div>
                          </div>
                          <div v-if="form.error.length > 0" class="block p-3 bg-red-100 text-red-500 leading-none mb-8">{{form.error}}</div>
                          <button v-if="!isLoadingForm" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;"
                            @click="buy()"
                          >
                            落札する
                          </button> 
                          <button v-if="isLoadingForm" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                            <pulse-loader :loading="true" color="#ffffff"></pulse-loader>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- モーダルここまで -->
                  </td>
                </tr>
                <!-- 繰り返しここまで  -->
              </table>
            </div>
          </div>
          <div id="bid_002_box" class="w-full">
            <table class="w-full border-collapse">
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  アセット名
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  {{nft.name}}(NFT)
                </td>
              </tr>
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  総発行枚数
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  {{nft.supply}} 🔒
                </td>
              </tr>
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  チェーン詳細
                </th>
                <td class="w-2/3 border-2 leading-none break-all p-3 text-sm font-medium align-top" style="color:630;">
                  <a :href="nft.explorerUrl" target="_blank">{{nft.explorerUrl}}</a>
                </td>
              </tr>
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  配布方法
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  <a :href="nft.distributedMethodUrl" target="_blank">{{nft.distributedMethodUrl}}</a>
                </td>
              </tr>
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  取扱開始日
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  {{nft.issuedAt}}
                </td>
              </tr>
              <tr>
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  クリエイター
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  <a :href="'https://twitter.com/'+nft.creator">{{nft.creator}}</a>
                </td>
              </tr>
            </table>
          </div>

          <div id="bid_003_box" class="w-full">
            <p>🕒1時間毎更新</p>

            <table class="w-full border-collapse">
              <tr v-for="(h,i) in holders" :key="h.id">
                <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-sm font-semibold text-right align-top" style="color:630;">
                  <div v-if="i==0">🥇{{h.balance}}枚</div>
                  <div v-else-if="i==1">🥈{{h.balance}}枚</div>
                  <div v-else-if="i==2">🥉{{h.balance}}枚</div>
                  <div v-else>{{h.balance}}枚</div>
                </th>
                <td class="w-2/3 border-2 leading-none p-3 text-sm font-medium align-top" style="color:630;">
                  <router-link :to="'/user/'+h.id">@{{h.screenName}}</router-link>
                </td>
              </tr>

            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>




<style>
#mona_bid_modal{ position:fixed; overflow:hidden; z-index:9999; width:0; height:0; top:50%; left:50%; background-color:rgba(0,0,0,0); transition:background-color 0.5s; }
#mona_bid:checked ~ #mona_bid_modal{ width:100%; height:100%; top:0; left:0; background-color:rgba(0,0,0,0.9); transition:background-color 0.5s; }
#bid_001_box,#bid_002_box,#bid_003_box{ display:none; }
#bid_001_label,#bid_002_label,#bid_003_label{ background-color:#EEE; color:#888; }
#bid_001:checked ~ #bid_001_label{ background-color:#FFF; color:#631; }
#bid_002:checked ~ #bid_002_label{ background-color:#FFF; color:#631; }
#bid_003:checked ~ #bid_003_label{ background-color:#FFF; color:#631; }
#bid_001:checked ~ #bid_001_box{ display:block; }
#bid_002:checked ~ #bid_002_box{ display:block; }
#bid_003:checked ~ #bid_003_box{ display:block; }
</style>




<script>
import { mapActions } from 'vuex'
import store from '../../store'
import Swal from 'sweetalert2'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import db from "../../firestore"
import config from "../../config"
import session from '../../utilities/session'
//const monaUtil = require("../api/monaUtil")

export default {
  components: {'PulseLoader': PulseLoader},
  data() {
    return {
      ticker: this.$route.params.ticker.toUpperCase(),
      selectedTicker: this.$route.params.ticker.toLowerCase(),
      nft: {},
      sellOrders: [],
      holders: [], // ホルダー一覧
      monaJpyPrice: null,
      form: {
        error: "",
        checks: []
      },
      selected: { // 板の落札ボタンが押されたときに代入される
        orderId: null,
        price: null
      },
      isLoadingForm: false,
      realTimeSellOrders: []
    }
  },
  firestore() {
    return {
      realTimeSellOrders: db.collection('ORDERBOOK_' + this.ticker).orderBy('price')
    }
  },
  mounted() {
    const thisObject = this
    this.isValidTicker(this.ticker).then(res => {
      if(!res.data.result) {
        Swal.fire({icon: 'error', title: "存在しないティッカーです", text: ""})
      } else {
        thisObject.nft = res.data.nft
        thisObject.initData()
      }
    })
  },
  methods: {
    ...mapActions(['isValidTicker', 'getOpenSellOrders', 'buyNft', 'getMonaRate', 'getHolders']),
    loginByTwitter() {
      session.setLastPath(this.$route.path)
      window.location = config.twitterLoginUrl
    },
    initData() {
      const thisObject = this
      this.getOpenSellOrders(this.ticker).then(res => {
        thisObject.sellOrders = res.data
      })
      this.getMonaRate().then(res => {
        thisObject.monaJpyPrice = res.data.rate
      })
      this.getHolders({ticker: this.ticker}).then(res => {
        thisObject.holders = res.data
      })
    },
    prepareBuy(order) {
      this.selected.orderId = order.orderId
      this.selected.price = order.price
      this.form.error = ""
      this.form.checks = []
    },
    // 落札する
    buy() {
      if(this.form.checks.length < 4) {
        this.form.error = "落札するには全ての項目に同意してください"
        return 
      }

      if(!this.isAuthenticated) {
        Swal.fire({icon: 'error', title: '', text: 'ログインが必要な操作です'})
        return
      }
      
      const thisObject = this
      thisObject.isLoadingForm = true
      thisObject.form.error = ""
      thisObject.buyNft({sellOrderId: this.selected.orderId}).then(res => {
        thisObject.$router.push('/history')
      }).catch(e => {
        thisObject.form.error = e.message
        thisObject.isLoadingForm = false
      })
    },
    // ticker クリック
    selectClick(event) {
      console.log(event.target.value)
      window.location.href = '#/nft/' + event.target.value;
      window.location.reload(true)
      //this.$router.go({path: '/nft/' + event.target.value, force: true})
      // this.$router.go({path: this.$router.currentRoute.path, force: true})

      // this.ticker = event.target.value.toUpperCase()
      // this.initData()
      // this.$forceUpdate()
      // this.firestore()
    }
  }
}
</script>