<template>
  <main>
    <div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      params: JSON.parse(this.$route.query.data)
    }
  },
  mounted() {
    this.loginWithTwitter(this.params)
  },
  methods: {
    ...mapActions(['loginWithTwitter'])
  }
}
</script>