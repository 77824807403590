<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">通知</h1>
      <div class="overflow-x-scroll">

        <p v-if="notifications!=null && notifications.length==0" class="font-bold">出品したNFTが落札されると表示されます</p>
        
        <table class="w-full border-collapse">
          <tr v-for="notification in notifications" :key="notification.createdAt">
            <th class="w-1/4 border-2 bg-gray-100 leading-none p-3 text-base font-semibold text-right align-top" style="color:630; min-width:200px;">
              {{notification.kidoku ? "": "🍫"}}{{notification.createdAt}}
            </th>
            <td class="w-3/4 border-2 leading-none p-3 text-base font-medium align-top whitespace-nowrap" style="color:630;">
              {{notification.str}}
            </td>
          </tr>       
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex'
import store from '../../store'

export default {
  data() {
    return {
      notifications: null
    }
  },
  mounted() {
    const thisObject = this
    // 通知を取得
    this.getNotification().then(res => {
      thisObject.notifications = res.data
      thisObject.$notificationCount = 0
    })
  },
  methods: {
    ...mapActions(['getNotification']),

  }
}
</script>