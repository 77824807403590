<template>
  <div>
    <div class="pt-12 pb-16">
      <div class="text-center mb-6"><img src="static/img/monaca.png" class="inline-block w-auto h-24"></div>
      <div class="leading-none text-2xl font-black mb-3 text-center" style="border-color:#EC8; color:#630;">CHOCOMONACA</div>
      <div class="leading-none text-base font-bold text-center" style="border-color:#EC8; color:#630;">暗号資産をモチーフにした<a href="https://twitter.com/chocomnc/status/1375756786242707458" target="_blank">NFT</a>取引所</div>
    </div>
    <div class="text-center w-full mb-4 lg:mb-8">
      <router-link to="/nft/mona" class="inline-block font-bold text-2xl lg:text-4xl p-8 bg-red-300 w-full" style="border-radius:.25rem; text-decoration:none; background-color:#631; color:#FFC;">
        マーケットに行く
      </router-link>
      <!-- <router-link v-if="!isAuthenticated" to="#" @click.native="loginByTwitter()" class="inline-block font-bold text-2xl lg:text-4xl p-8 bg-red-300 w-full" style="border-radius:.25rem; text-decoration:none; background-color:#631; color:#FFC;">
        ログインして抽選に参加する
      </router-link> -->
      <div class="flex justify-center leading-none "><img src="static/img/border.png" class="inline-block w-auto h-4"></div>
    </div>
    <div class="bg-white p-8 lg:p-16 text-center font-bold text-4xl mb-3 lg:mb-8" style="border-radius:.5rem;">
      <img src="static/img/howto.png" class="w-full h-auto">
      <br />
      <div class="w-full lg:w-1/2 lg:pr-1 mb-2"><a href="https://note.com/chocomnc/n/n33086ab45230" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
        <span class="note">チョコモナカの仕組み</span>
      </a></div>
    </div>
    <div class="bg-white p-8 lg:p-16 font-bold text-4xl" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">説明書</h1>
      <div class="flex flex-wrap">
        <!-- <div class="w-full lg:w-1/2 lg:pr-1 mb-2"><a href="" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
          <span class="note">はじめ方</span>
        </a></div> -->
        <div class="w-full lg:w-1/2 lg:pl-1 mb-2"><a href="https://note.com/chocomnc/n/ne4cf0f27bc53" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
          <span class="note">①モナコインのウォレットを用意する</span>
        </a></div>
        <div class="w-full lg:w-1/2 lg:pr-1 mb-2"><a href="https://note.com/chocomnc/n/n7c5527b935e2" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
          <span class="note">②取引所からウォレットへMONAを送る</span>
        </a></div>
        <div class="w-full lg:w-1/2 lg:pl-1 mb-2"><a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
          <span class="note">③NFTを落札する</span>
        </a></div>
        <div class="w-full lg:w-1/2 lg:pr-1"><a href="https://note.com/chocomnc/n/nb97572686209" target="_blank" class="p-4 block text-xl border-2 bg-gray-100" style="text-decoration:none;">
          <span class="note">④NFTを出品する</span>
        </a></div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  async mounted() {
    // console.log("@@@")

    // console.log(this.mpurse)

    // console.log("@p@p@")
  
    // const address = await this.mpurse.getAddress()
    // console.log(address)

  },
  methods: {
  }
}
</script>