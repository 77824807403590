<template>
  <footer>
    <div id="footer" class="container w-3/5 flex justify-center flex-wrap lg:justify-between mx-auto pb-8 lg:pb-16">
      <div class="flex hidden lg:block w-full mb-2 pb-2 border-b-2 border-dotted" style="border-color:#d9a846;">
        <router-link to="/" class="inline-block font-medium leading-none mr-8">ホーム</router-link>
        <a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank" class="inline-block font-medium leading-none mr-8">📙落札ガイド</a>
        <a href="https://note.com/chocomnc/n/nb97572686209" target="_blank" class="inline-block font-medium leading-none mr-8">📗出品ガイド</a>

      </div>
      <div class="flex hidden lg:block w-full border-b-2 border-dotted mb-4 pb-2" style="border-color:#d9a846;">
        <a href="https://tayori.com/faq/4b6d714a0add239bee0d5ecc874e525e4531766a" target="_blank" class="inline-block font-medium leading-none mr-8">サポート</a>
        <router-link to="/terms" class="inline-block font-medium leading-none mr-8">利用規約</router-link>
        <router-link to="/fee" class="inline-block font-medium leading-none mr-8">手数料</router-link>
        <a href="https://twitter.com/chocomnc" target="_blank" class="inline-block font-medium leading-none mr-8">Twitter</a>
        <a href="https://discord.gg/D2K8eMyDrw" target="_blank" class="inline-block font-medium leading-none mr-8">Discord</a>
        <a href="https://note.com/chocomnc/n/na91aebd5ee4b" target="_blank" class="inline-block font-medium leading-none mr-8">NFTクリエイター募集</a>
      </div>
      <div class="leading-none font-semibold w-full flex justify-center lg:justify-end">
        ©2021 CHOCOMONACA.&nbsp;
      </div>
    </div>
  </footer>
</template>




<style>
  #footer a{ color:#631; text-decoration:underline; }
</style>




<script>
export default {
  name: 'FooterTemplate',
}
</script>