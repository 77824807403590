<template>
  <header>
    <div id="header" class="w-full">
      <div class="container w-3/5 py-4 px-4 lg:px-0 mx-auto flex justify-between">
        <div><router-link to="/"><img src="static/img/logo.png" class="w-auto h-8"></router-link></div>
        <div class="flex hidden lg:block">
          <router-link v-if="isAuthenticated" to="/collection" class="inline-block text-base leading-none font-medium ml-8 py-2 ">コレクション</router-link>
          <router-link to="/nft/mona" class="inline-block text-base leading-none font-medium ml-8 py-2">マーケット</router-link>
          <router-link to="/chart/mona" class="inline-block text-base leading-none font-medium ml-8 py-2">チャート</router-link>
          <router-link v-if="isAuthenticated" to="/history" class="inline-block text-base leading-none font-medium ml-8 py-2 ">履歴</router-link>
          <!-- PC -->
          <router-link v-if="isAuthenticated" to="/notification" class="inline-block text-base leading-none font-medium ml-8">
            <div class="flex">
              <span id="bell">通知</span>
              <span v-if="$notificationCount > 0" id="bell_number">{{$notificationCount}}</span>
            </div>
          </router-link>
          <router-link v-if="isAuthenticated" to="/setting" class="inline-block text-base leading-none font-medium ml-8 py-2 ">設定</router-link>
          <router-link v-if="!isAuthenticated" to="#" @click.native="loginByTwitter()" class="inline-block leading-none ml-8 py-2 px-3 hover:no-underline" style="background-color:#FFC; border-radius:.125rem;">
            <span id="twitter_login" class="inline-block leading-none font-bold text-base" style="color:#631;">ログイン</span>
          </router-link>
        </div>
        <div class="block lg:hidden">
          <input id="mobile" type="checkbox" class="hidden" @click="goShowHeader()" :checked="showingHeader ? 'checked' : ''">
          <label for="mobile" class="inline-block leading-none cursor-pointer"><img src="static/img/mobile.png" class="w-8 h-8"></label>
          <div id="mobile_box">
            <div class="p-4">
              <div class="text-right"><label for="mobile" class="inline-block leading-none cursor-pointer"><img src="static/img/close.png" class="w-8 h-8"></label></div>
              <div class="mb-4">
                <span class="block leading-none font-bold text-sm mb-3" style="color:#B62;">基本操作</span>
                <router-link v-if="!isAuthenticated" to="#" @click.native="loginByTwitter()" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">登録/ログイン</router-link>
                <router-link v-if="isAuthenticated" to="/collection" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">コレクション</router-link>
                <router-link to="/nft/mona" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">マーケット</router-link>
                <router-link to="/chart/mona" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">チャート</router-link>
                <router-link v-if="isAuthenticated" to="/history" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">履歴</router-link>
                <!-- モバイル -->
                <router-link v-if="isAuthenticated" to="/notification" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">
                  <div class="flex">
                    <span>通知</span>
                    <span v-if="$notificationCount > 0" id="bell_mobile_number">{{$notificationCount}}</span>
                  </div>
                </router-link>
                <router-link v-if="isAuthenticated" to="/setting" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">設定</router-link>
                <!-- <router-link to="/lottely" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">ICL</router-link> -->
              </div>
              <div class="mb-4">
                <span class="block leading-none font-bold text-sm mb-3" style="color:#B62;">情報</span>
                <a href="https://note.com/chocomnc/n/ne682656f01bc" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">📙落札ガイド</a>
                <a href="https://note.com/chocomnc/n/nb97572686209" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">📗出品ガイド</a>

                <a href="https://tayori.com/faq/4b6d714a0add239bee0d5ecc874e525e4531766a" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">サポート</a>
                <router-link to="/terms" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">利用規約</router-link>
                <a href="https://twitter.com/chocomnc" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">Twitter</a>
                <a href="https://discord.gg/D2K8eMyDrw" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">Discord</a>
                <a href="https://note.com/chocomnc/n/na91aebd5ee4b" target="_blank" class="block text-base leading-none border-b-2 font-bold border-dotted py-3 truncate" style="border-color:#EC8; color:#630;">NFTクリエイター募集</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="border" class="w-full h-2"></div>
  </header>
</template>




<style>
body{  }
  body{ white-space: normal; word-break:break-all; }
  h1,h2,h3,h4,th,label,div{ white-space: normal; }
  label{ margin:0; padding:0; }
  #header{
    background: #440000;
    background: -moz-linear-gradient(top,  #440000 1%, #663311 100%);
    background: -webkit-linear-gradient(top,  #440000 1%,#663311 100%);
    background: linear-gradient(to bottom,  #440000 1%,#663311 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#440000', endColorstr='#663311',GradientType=0 );
  }
  #header a{ color:#FFC; }
  #border{ background-image:url(/static/img/border.png); background-repeat:repeat-x; background-size:auto 100%; }
  #mobile_box{ position:fixed; z-index:999; max-width:0; height:100%; top:0; right:0; background-color:rgba(255,255,255,0.9); transition:max-width .125s; overflow-y:scroll; }
  #mobile:checked ~ #mobile_box{ max-width:100%; width:100%; transition:max-width .125s; }
  #bell{ padding-left:1.5rem; background-image:url(/static/img/bell.png); background-size:auto 100%; background-repeat:no-repeat; }
  #bell_number{ min-width:1rem; max-height:1rem; border-radius:.125rem; font-size:12px; padding:2px 0; background-color:#B30; font-weight:500; text-align:center; margin-left:.5rem; color:#FFF; }
  #bell_mobile_number{ min-width:1rem; max-height:1rem; border-radius:.125rem; font-size:12px; padding:2px 0; background-color:#B30; font-weight:500; text-align:center; margin-left:.5rem; color:#FFF; }
  #twitter_login{ padding-left:1.75rem; background-image:url(/static/img/twitter_login.png); background-size:auto 100%; background-repeat:no-repeat; }
  .note{ display:inline-block; line-height:100%; padding-left:2.25rem; background-image:url(/static/img/note.png); background-size:1.25rem 1.25rem; background-repeat:no-repeat; color:#666; }
  .preview{ display:inline-block; line-height:100%; padding-left:.9rem; background-image:url(/static/img/arrow_001.png); background-size:.4rem .75rem; background-repeat:no-repeat; }
  .next{ display:inline-block; line-height:100%; padding-right:.9rem; background-image:url(/static/img/arrow_002.png); background-position:right; background-size:.4rem .75rem; background-repeat:no-repeat; }
  .withdraw_on{ display:inline-block; line-height:100%; padding-left:1.375rem; background-image:url(/static/img/withdraw_on.png); background-size:.875rem .75rem; background-repeat:no-repeat; }
  .withdraw_off{ display:inline-block; line-height:100%; padding-left:1.375rem; background-image:url(/static/img/withdraw_off.png); background-size:.875rem .75rem; background-repeat:no-repeat; }
  .deposit_on{ display:inline-block; line-height:100%; padding-left:1.375rem; background-image:url(/static/img/deposit_on.png); background-size:.875rem .75rem; background-repeat:no-repeat; }
  .deposit_off{ display:inline-block; line-height:100%; padding-left:1.375rem; background-image:url(/static/img/deposit_off.png); background-size:.875rem .75rem; background-repeat:no-repeat; }
</style>




<script>
import { mapActions, mapGetters } from 'vuex'
import store from '../../store'
import config from "../../config"
import session from '../../utilities/session'

export default {
  name: 'Header',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['showingHeader']),
  },
  mounted() {
    const thisObject = this
    if(this.isAuthenticated) {
      this.getNotificationCount().then(res => {
        thisObject.$notificationCount = res.data.count
      })
    }
  },
  methods: {
    ...mapActions(['getNotificationCount']),
    goShowHeader() {
      store.commit('showHeader')
    },
    loginByTwitter() {
      session.setLastPath(this.$route.path)
      window.location = config.twitterLoginUrl
    },
  },
}
</script>