import axios from 'axios'

import store from '../store'
import router from '../router'

/* eslint-disable no-param-reassign */


// Add a request interceptor
axios.interceptors.request.use(
  cfg => {
    if (store.getters.isAuthenticated) {
      cfg.headers['x-access-token'] = store.getters.auth
    }
    return cfg
  },
  err => {
    return Promise.reject(err)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  res => {
    return res
  },
  err => {

    // if (err.response.status === 401) {
    //   store.commit('setAuthentication')
    //   router.push({
    //     path: '/auth/login',
    //     query: {
    //       redirect: router.app._route.fullPath,
    //     },
    //   })
    // }
    return Promise.reject(err)
  },
)

export default axios
