<template>
  <div>
    <div class="bg-white p-8 lg:p-16" style="border-radius:.5rem;">
      <h1 class="leading-none text-2xl lg:text-4xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
        履歴
      </h1>
      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        落札履歴
      </h2>
      <div class="overflow-x-scroll mb-2">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">アクション</th>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">NFT</th>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">価格</th>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">送り先アドレス</th>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              ステータス
              <button id="tippy" :content="tippy.statusRakusatsu" v-tippy="{trigger : 'click'}">❔</button> 
            </th>
            <th class="w-1/6 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">日時</th>
          </tr>
          <p v-if="buyHistoryData!=null && buyHistoryData.length==0">履歴が存在しません</p>
          <tr v-for="history in buyHistoryData" :key="history.id">
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              <input type="checkbox" id="mona_history" class="hidden" v-model="showQrModal">
              <label for="mona_history" class="cursor-pointer" v-if="history.status == '入金待ち'">
                表示
              </label> 
              <div id="mona_history_modal">
                <label @click="closeQr()" class="absolute block w-full h-full cursor-pointer" style="z-index:-1;"></label>
                <div class="w-full mx-auto xl:w-1/2 bg-white p-8 max-h-full overflow-y-scroll" style="max-width:90%; max-height:90%; margin-top:5%; border-radius:.25rem;">
                  <div class="text-right">
                    <label @click="closeQr()" class="inline-block cursor-pointer">
                      <img src="static/img/close.png" class="inline-block w-8 lg:w-16 h-8 lg:h-16">
                    </label>
                  </div>
                  <div class="font-bold text-base">
                    <div border="1">
                      <h1 class="leading-none text-xl lg:text-2xl lg:text-xl font-bold mb-8 border-l-8 pl-4" style="border-color:#EC8; color:#630;">
                        {{depositInfo.conf==null ? "モナコインを送金してください": "送金確認済み"}}
                      </h1>
                      <div v-if="depositInfo.conf==null" class="overflow-x-scroll mb-8">
                        <table class="w-full">
                          <tbody>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2" style="min-width:6rem;">
                                
                              </th>
                              <td id="qrcode" class="text-base leading-none p-3 border-2">
                                <a href="#" @click="showHideQR()">
                                  <div v-if="showQrImage">QRコードを隠す</div>
                                  <div v-if="!showQrImage">QRコードを表示</div>
                                  <vue-qrcode v-if="payment.qrString!='' && showQrImage" :value="payment.qrString" />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">
                                アドレス
                              </th>
                              <td class="text-base leading-none p-3 border-2">
                                {{payment.address}}
                                <button id="tippy" :content="tippy.copyAddress" v-tippy="{trigger : 'click'}" class="clipcopy" v-bind:data-clipboard-text="payment.address">&nbsp;コピー&nbsp;</button>
                              </td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">
                                数量
                                <button id="tippy" :content="tippy.amount" v-tippy="{trigger : 'click'}">❔</button>
                              </th>
                              <td class="text-base leading-none p-3 border-2">
                                {{Number(payment.amount)}} MONA
                                <button id="tippy" :content="tippy.copyAmount" v-tippy="{trigger : 'click'}" class="clipcopy" v-bind:data-clipboard-text="Number(payment.amount)">&nbsp;コピー&nbsp;</button>
                                <br /><br />（{{Number(payment.amount)}}MONAぴったりを送金して下さい）
                              </td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">
                                残り
                                <button id="tippy" :content="tippy.time" v-tippy="{trigger : 'click'}">❔</button>
                              </th>
                              <td :class="getClassName()">
                                {{payment.remainString}}
                              </td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">
                                困ったときは
                              </th>
                              <td class="text-base leading-none p-3 border-2">
                                送金を行わず、サポートにご連絡ください。
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="overflow-x-scroll mb-8">
                        <table class="w-full" v-if="depositInfo.txid != ''">
                          <tbody>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">txid</th>
                              <td class="text-base leading-none p-3 border-2">
                                <a :href="'https://blockbook.electrum-mona.org/tx/'+depositInfo.txid" target="_blank">{{getShortTxid(depositInfo.txid)}}</a>
                              </td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2">確認数</th>
                              <td class="text-base leading-none p-3 border-2">{{depositInfo.conf}} / 20</td>
                            </tr>
                            <tr>
                              <th class="text-base leading-none p-3 bg-gray-100 border-2"></th>
                              <td class="text-base leading-none p-3 border-2">確認数が20になるまでしばらくお待ち下さい（約30分）</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div v-if="depositInfo.error.length > 0" class="block p-3 bg-red-100 text-red-500 leading-none mb-8">{{depositInfo.error}}</div>

                      <button v-if="!depositInfo.loading && depositInfo.conf < 20" @click="checkDeposit()" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                        送金チェック
                      </button> 
                      <button v-if="depositInfo.loading" class="block w-full text-center font-medium text-xl lg:text-2xl leading-none py-4 lg:py-8 cursor-pointer" style="color:#FFC; background-color:#630; border-radius:.25rem;">
                        <pulse-loader :loading="true" color="#ffffff"></pulse-loader>
                      </button> 
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.ticker}}(NFT)
            </td>
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.price}} MONA
            </td>
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.sendAddress}}
            </td>
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.status}}
            </td>
            <td class="w-1/6 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.date}}
            </td>
          </tr>
        </table>
      </div>
      <div class="flex justify-end mb-8">
        <div class="border flex" style="border-radius:.125rem;">
          <button v-if="page.rakusatsu > 0" class="inline-block font-bold px-3 py-2 bg-gray-100 border" style="min-width:80px;"
            @click="rakusatsuPrevious()"
          >
            <span class="preview">前へ</span>
          </button>
          <button v-if="buyHistoryData && buyHistoryData.length==10" class="inline-block font-bold px-3 py-2 bg-gray-100 border" style="min-width:80px;"
            @click="rakusatsuNext()"
          >
            <span class="next">次へ</span>
          </button>
        </div>
      </div>

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">
        出品履歴
      </h2>
      <div class="overflow-x-scroll mb-2">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              NFT
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              価格
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              受取アドレス
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              ステータス
              <button id="tippy" :content="tippy.statusShuppin" v-tippy="{trigger : 'click'}">❔</button>
            </th>
            <th class="w-1/5 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">
              日時
            </th>
          </tr>
          <p v-if="sellHistoryData!=null && sellHistoryData.length==0">履歴が存在しません</p>
          <tr v-for="history in sellHistoryData" :key="history.id">
            <td class="w-1/5 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.ticker}}(NFT)
            </td>
            <td class="w-1/5 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.price}} MONA
            </td>
            <td class="w-1/5 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.sendAddress}}
            </td>
            <td class="w-1/5 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.status}}
            </td>
            <td class="w-1/5 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">
              {{history.date}}
            </td>
          </tr>
        </table>
      </div>
      <div class="flex justify-end mb-8">
        <div class="border flex" style="border-radius:.125rem;">
          <button v-if="page.shuppin > 0" class="inline-block font-bold px-3 py-2 bg-gray-100 border" style="min-width:80px;"
            @click="shuppinPrevious()"
          >
            <span class="preview">前へ</span>
          </button>
          <button v-if="sellHistoryData && sellHistoryData.length==10" class="inline-block font-bold px-3 py-2 bg-gray-100 border" style="min-width:80px;"
            @click="shuppinNext()"
          >
            <span class="next">次へ</span>
          </button>
        </div>
      </div>

      <h2 class="border-t-2 lg:border-t-4 border-b-2 lg:border-b-4 leading-none py-3 mb-8 text-base lg:text-2xl" style="color:#630;">エアードロップ履歴</h2>
      <div class="overflow-x-scroll">
        <table class="w-full border-collapse">
          <tr>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">NFT</th>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">数量</th>
            <th class="w-1/3 border-2 bg-gray-100 leading-none p-3 text-base font-semibold whitespace-nowrap" style="color:630;">日時</th>
          </tr>
          <p v-if="airdropHistoryData!=null && airdropHistoryData.length==0">履歴が存在しません</p>
          <tr v-for="history in airdropHistoryData" :key="history.id">
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">{{history.ticker}}(NFT)</td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">{{history.amount}}</td>
            <td class="w-1/3 border-2 leading-none p-3 text-base font-medium whitespace-nowrap" style="color:630;">{{history.date}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>




<style>
#mona_history_modal{ position:fixed; overflow:hidden; z-index:9999; width:0; height:0; top:50%; left:50%; background-color:rgba(0,0,0,0); transition:background-color 0.5s; }
#mona_history:checked ~ #mona_history_modal{ width:100%; height:100%; top:0; left:0; background-color:rgba(0,0,0,0.9); transition:background-color 0.5s; }
#qrcode img{ width:50%; min-width:200px; min-height:200px; }
</style>




<script>
import { mapActions } from 'vuex'
import VueQrcode from 'vue-qrcode'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { tippy } from "vue-tippy"
import clipboard from 'clipboard'

import moment from 'moment-timezone'
moment.tz.setDefault("Asia/Tokyo");

export default {
  components: { VueQrcode , 'PulseLoader': PulseLoader},
  data() {
    return {
      buyHistoryData: null,
      sellHistoryData: null,
      airdropHistoryData: null,
      showQrModal: false, // modalの表示・非表示
      showQrImage: true, // QR画像の表示・非表示
      payment: {
        qrString: "",
        address: "",
        amount: null,
        lastUpdateUnix: null, // 落札時刻
        remainString: ""
      },
      depositInfo: {
        loading: false,
        txid: "",
        conf: null,
        error: "",
        lastPushed: null
      },
      page: {
        rakusatsu: 0, // 落札履歴のページindex
        shuppin: 0,
      },
      tippy: {
        copyAddress: "アドレスをコピーしました",
        copyAmount: "数量をコピーしました",
        time: "落札後60分以内に上記のモナコインアドレスに対して指定数量を送金してください。60分以内に送金が検出されない場合、7日間アカウントがロックされます。",
        amount: "正確な数量を送金してください。数量を間違えた場合、資金は失われます。",
        statusShuppin: "「取引失敗」は落札者が落札後60分以内に送金を行わなかった取引です。出品は自動でキャンセルされているため、売却したい場合は再度出品を行ってください。",
        statusRakusatsu: "「取引失敗」は落札後60分以内にあなたが送金を行わなかった取引です。その時点からあなたのアカウントは7日間ロックされます。"
      }
    }
  },
  mounted() {
    this.initData()
    setInterval(this.getRemainingString, 1000)

    this.clipBoard = new clipboard('.clipcopy')
    this.clipBoard.on('success', function(e) {     
      e.clearSelection()
    })
    this.clipBoard.on('error', function(e) {
    })
  },
  methods: {
    ...mapActions(['buyHistory', 'sellHistory', 'airdropHistory', 'checkDepositManually']),
    initData() {
      const thisObject = this
      this.buyHistory({page: this.page.rakusatsu}).then(res => {
        thisObject.buyHistoryData = res.data.history
        const result = thisObject.buyHistoryData.filter(function(element, index, array) {
          return element.status.indexOf('入金待') != -1
        })
        if(result.length > 0) {
          thisObject.showQr()
        }
        // 入金チェックデータ
        if(res.data.txInfo.result) {
          thisObject.depositInfo.txid = res.data.txInfo.model.txid
          thisObject.depositInfo.conf = res.data.txInfo.model.conf
          thisObject.depositInfo.error = ""
        }
      })
      // 出品履歴
      this.reloadSellHistory()
      // エアードロップ履歴
      this.airdropHistory().then(res => {
        thisObject.airdropHistoryData = res.data
      })
    },
    // QR画像の表示非表示
    showHideQR() {
      this.showQrImage = !this.showQrImage
    },
    // 出品履歴取得
    reloadSellHistory() {
      const thisObject = this
      this.sellHistory({page: this.page.shuppin}).then(res => {
        thisObject.sellHistoryData = res.data
      })
    },
    // 出品履歴　前へ
    shuppinPrevious() {
      this.page.shuppin--
      this.reloadSellHistory()
    },
    // 出品履歴　次へ
    shuppinNext() {
      this.page.shuppin++
      this.reloadSellHistory()
    },
    // 落札履歴取得
    reloadBuyHistory() {
      const thisObject = this
      this.buyHistory({page: this.page.rakusatsu}).then(res => {
        thisObject.buyHistoryData = res.data.history
      })
    },
    // 落札履歴　前へ
    rakusatsuPrevious() {
      this.page.rakusatsu--
      this.reloadBuyHistory()
    },
    // 落札履歴　次へ
    rakusatsuNext() {
      this.page.rakusatsu++
      this.reloadBuyHistory()
    },
    // QRコードを表示する
    showQr() {
      const result = this.buyHistoryData.filter(function(element, index, array) {
        return element.status.indexOf('入金待') != -1
      })
      if(result.length > 0) {
        const address = result[0].sendAddress
        const amount = result[0].price
        const lastUpdate = result[0].lastUpdate
        this.showQrModal = true
        // monacoin:PTiRsfHcmEcUSBi7Kxtv4Fv6A6MTE85R5p?amount=0.10000000&message=test
        this.payment.qrString = address //"monacoin:" + address + "?amount=" + amount + "&message=chocomonaca"
        this.payment.address = address
        this.payment.amount = amount
        this.payment.lastUpdateUnix = lastUpdate
      }
    },
    closeQr() {
      this.showQrModal = false
    },
    // 短縮txid
    getShortTxid(txid) {
      const strs = txid.match(/.{4}/g);
      return strs[0] + "...." + strs[strs.length-1]
    },
    // 入金チェック
    checkDeposit() {
      const date = new Date() 
      const now = Math.floor(date.getTime() / 1000)
      if(now - this.depositInfo.lastPushed < 10) {
        this.depositInfo.error = "10秒待ってください🙆‍♀️"
        return
      }

      const thisObject = this
      thisObject.depositInfo.loading = true
      this.checkDepositManually().then(res => {
        if(res.data.result) {
          thisObject.depositInfo.txid = res.data.model.txid
          thisObject.depositInfo.conf = res.data.model.conf
          thisObject.depositInfo.error = ""
          thisObject.depositInfo.loading = false
        } else {
          if(thisObject.depositInfo.txid.length > 0) {
            // 既に検出されている状態で必要conf数を超えたためfalseが返ってくる、リロードする
            console.log("リロード")
            thisObject.$router.go({path: thisObject.$router.currentRoute.path, force: true})
          } else {
            thisObject.depositInfo.error = "送金は検出されませんでした"
            thisObject.depositInfo.loading = false
          }
        }
      }).catch((e) => {
        thisObject.depositInfo.loading = false
      })
      thisObject.depositInfo.lastPushed = now
    },
    getClassName() {
      if(this.payment.lastUpdateUnix == null) return
      const RED_LIMIT = 60 * 50 // 50分経過した場合
      if(moment().unix() - this.payment.lastUpdateUnix > RED_LIMIT) {
        return "text-base leading-none p-3 border-2 text-red-500"
      } else {
        return "text-base leading-none p-3 border-2"
      }
    },
    // 残り時間
    getRemainingString() {
      if(this.payment.lastUpdateUnix == null) return
      const LIMIT = 60 * 60
      if(moment().unix() - this.payment.lastUpdateUnix > LIMIT) {
        this.payment.remainString = "00:00"
      } else {
        const sa = moment().unix() - this.payment.lastUpdateUnix
        const remain = LIMIT - sa
        const min = parseInt(remain / 60)
        const sec = remain % 60
        this.payment.remainString = ('0'+min).slice(-2) + ":" + ('0'+sec).slice(-2)
      }
    }
  }
}
</script>

<style scoped>
#tippy { border:0; background-color:#5f3619; color:#FFF; font-size:12px; display:inline-block; line-height:160%; text-align: center; margin-left:10px; border-radius:2px; }
</style>